@import '../../../../../core/ui/assets/css/variables';

.form-btn-delete {
  color: #ff0000;
  cursor: pointer;
}

.map-editor-canvas.hmi {
  position: relative;

  .canvas-container {
    margin: 85px 20px 0 auto;
    border: 1px solid #000;
    overflow: hidden;
    border-radius: 6px;

    canvas {
      border-radius: 15px;
    }
  }
}

.map-editor {
  &.hmi {
    position: relative;
    //padding-top: 5px;
  }
}

.hmi-editor-container {
  .button-row {
    margin-top: 50px;
  }
}

.edit-object-form  {
  hr {
    margin: 0 0 20px;
  }

  padding-bottom: 50px;
}

.autocomplete-field {
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 0;
  }

  .MuiInputBase-root.MuiOutlinedInput-root fieldset {
    border-radius: 6px;
  }

  .MuiAutocomplete-endAdornment {
    z-index: 1;
    top: 3px;
  }

  &.success-field,
  &.error-field {
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
      right: 30px;
    }
  }
  input::placeholder {
    font-style: normal !important;
  }
}

.MuiAutocomplete-option {
  font-family: $font !important;
  font-size: 16px;
}

.MuiAutocomplete-paper {
  font-family: $font !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px
}

.size-setting-for-object,
.font-setting-for-object {
  box-shadow: none !important;
  border: 1px solid #adb5bd;
  border-radius: 6px !important;
  overflow: hidden;
  margin-top: 8px !important;
  margin-bottom: 16px !important;

  .MuiAccordionSummary-content {
    margin: 10px 0 !important;
  }

  .MuiAccordionSummary-root {
    min-height: 40px !important;
  }

  .MuiIconButton-root {
    padding: 8px !important;
  }

  .MuiAccordionDetails-root {
    flex-direction: column;
  }
}

.size-setting-for-object-label,
.font-setting-for-object-label {
  font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
  color: #333 !important;
}