.info-modal {
    width: 484px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 4px 20px 0 rgba(33, 37, 41, 0.2), 0 1px 7px 0 rgba(0, 0, 0, 0.15);
    padding: 16px 32px 24px;
    position: absolute;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2 {
        text-align: center;
    }

    p, div {
        text-align: center;
    }

    @media screen and (max-width: 640px) {
        width: 400px;
    }

    @media screen and (max-width: 375px) {
        width: 320px;
    }
}