.language-switcher {

  &.login-page {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .MuiSelect-select {
    font-size: 15px;
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
  }
  .MuiSelect-select:focus {
    background: none;
  }
}


.language-item {
  font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
  font-size: 14px !important;
  font-style: normal;
  line-height: 32px!important;
  letter-spacing: normal;
  color: #333333;
  padding: 0 24px !important;
}
