$font:'IBM Plex Sans',
Arial,
Helvetica,
sans-serif;

$text: #333333;
$text-grey: #adb5bd;
$grey: #e6eaed;
$blue: #4092f5;

$diagram-green: #38a43a;
$diagran-pirple:#c117a7;
$diagram-pink:#fc5265;
$diagram-red: #e9441b;
$diagram-burgundy:#880856;
$diagram-violert:#a858fa;
$diagram-blue: #1061e5;
$diagram-brown: #be761b;
$diagram-dark-blue: #022166;
$diagram-light-blue: #5d91eb;

$state-red-line: url('../images/red-line.png') 0 0 / 6px;
$state-red:#eb444b;
$state-brown:#cf7f55;
$state-yellow:#f0d085;
$state-grey-line: url('../images/grey-line.png') 0 0 / 6px;
$state-green:#72c26a;

$bar-blue:#a7cee2;
$bar-green:#b3de8e;
$bar-grey:#d0d4d6;
$bar-orange:#fcbe75;
$bar-violet: #cab3d5;

$new-sensor: #75C36E;
$message: #fff4ba;
$battleship-grey: #74797d;

$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;