$battleship-grey: #74797d;
$dodger-blue: #4092f5;

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  width: 100%;
  margin-right: 20px;
}

.slider {
  display: flex;

  :global {
    .MuiSlider-root {
      width: 148px;
      height: 2px;
      border-radius: 5px;
      color: $dodger-blue;
    }

    .MuiSlider-rail {
      color: $battleship-grey;
      opacity: 1;
    }

    .MuiSlider-thumb {
      width: 20px;
      height: 20px;
      margin-top: -10px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
      border: solid 1px rgba(0, 0, 0, 0.05);
      background-color: #ffffff;
    }
  }
}

.value {
  margin-left: 20px;
  min-width: 40px;
}
