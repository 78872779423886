#delete-object-dialog {
    .dialog-title {
        font-size: large;
    }

    form {
        text-align: left;
    }

    .color-danger {
        color: #ff3b30
    }

    .button-row {
        margin-top: 1.5rem;
    }

    .label-firts-letter-capitalize {
        text-transform: lowercase;
        
        & > .MuiTypography-body1::first-letter {
            text-transform: capitalize;
            
        }
    }
}