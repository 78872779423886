.gps-pin-form {
    &.form {
        margin: 0 auto;
        max-width: 170px;
    }

    .form-field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;

        .MuiFormLabel-root {
            margin: 0;
        }

        .MuiFormControl-fullWidth {
            max-width: 100px;
        }
    }

    .form-group {
        display: flex;
        flex-flow: column wrap;
    }

    &.button-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 23px;

        .MuiButton-root {
            margin: 0 6px;
        }
    }
}
