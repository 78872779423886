@import "../../../core/ui/assets/css/variables";

.btn-wrap.states {
    display: flex;
    max-width: 1054px;
    .message .MuiSnackbarContent-root{
        margin: 0;
        box-shadow: none;
    }
    .message.visible {
        max-width: inherit;
        flex: 1;
    }
    .MuiSnackbarContent-action {
        visibility: hidden;
    }
    @media screen and (max-width: 960px){
        display: inline-block;
        width: 100%;
        .message .MuiSnackbarContent-root{
            margin-top: 15px;
        }
    }
}

.list-color-radio-btn {
    margin-bottom: 23px !important;
    max-width: 320px;
    .form-field, .form-fields{
        margin: 0;
    }

    .MuiSvgIcon-root {
        height: 35px;
        width: 35px;
    }



    .PrivateRadioButtonIcon-checked-267 {
        box-shadow: inset 0 0 0 3px #4092f5;
        border-radius: 100%;
    }
}

.form-add-edit-states {
    .title {
        font-family: $font;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        color: $text;
    }
    label,
    legend {
        font-family: $font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        margin-bottom: 14px;
        letter-spacing: normal;
        color: $text;
    }


    .form-field .MuiFormLabel-root, .form-fields .MuiFormLabel-root {
        margin-bottom: 14px;
    }
    span.MuiIconButton-label > div > svg:last-child {
        transform: scale(2);
    }
    .Mui-checked{
        .MuiIconButton-label > div{
            &:before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border: 3px solid $blue;
                border-radius: 100%;
            }
        }
    }

    .MuiRadio-colorSecondary,
    .MuiIconButton-colorSecondary:hover,
    .MuiRadio-colorSecondary.Mui-checked:hover {
        background-color: transparent;
    }
}
.wrapper-states {

    .list-draggable {
        min-height: 40px;
    }

    .list-draggable__item-include.unsorted{
        svg {
            visibility: hidden;
        }
    }
}