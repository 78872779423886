@import "./src/core/ui/assets/css/variables";

.product-select-wrap-monitor {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 18px;
  z-index: 9999;
  button.button {
    margin-right: 8px;
    z-index: 3;

    &.selected {
      border: none;
      border-radius: 4px;
      background: #f1f3f5;
      box-shadow: none;
      height: 12px;
    }
  }


  .button:not(.selected):hover,
  .button:not(.selected) {
    position: relative;
    padding: unset;
    background: transparent;
    justify-content: flex-end;
    border: none;
    height: 12px;
    box-shadow: none;

    svg{
      background: rgba(#ffffff,.8);
    }
  }

}

.product-select-monitor {
  .ul-wrap {
    min-width: 143px;
    padding: 16px 0;
  }

  a,
  li {
    height: 32px;
    font-family: $font;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 32px;
    letter-spacing: normal;
    color: $text;
    padding: 0 24px;
    //max-width: 145px;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: keep-all;
    }
    svg {
      min-width: 24px;
    }
  }

  li.title {
    height: 24px;
    font-family: $font;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 1.5px;
    color: $text;
    text-transform: uppercase;
    cursor: inherit;

    &:hover {
      background: inherit;
    }
  }



}

.time-wrap {
  .Mui-disabled {
    opacity: 0.5
  }

  .btn-group {
    button:first-child{
      margin-right: 34px;
    }
  }

  .time-group {
    margin-top: 16px;
    padding-bottom: 24px;
    flex-direction: column;
    align-items: center;
    max-width: 342px;
    margin-right: auto;
    margin-left: auto;

    &-item {
      flex-direction: row;
      display: flex;
      width: 100%;
      justify-content: space-between;

      & > div {
        display: flex;
      }
    }

    .label,
    .MuiTypography-root.MuiFormControlLabel-label, .MuiFormControl-root .MuiFormLabel-root{
      height: 40px;
      line-height: 40px;
      //flex: 1;
      font-family: $font;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $text;
      margin: 0 0 8px 0;
      &.start-time {
        padding-left: 27px;
      }
    }

    .MuiTypography-root.MuiFormControlLabel-label, .MuiFormControl-root .MuiFormLabel-root{
      margin-bottom: 0;
    }

    .MuiFormControl-marginNormal {
      max-width: 100px;
      margin-top: 0;
      padding-left: 1px;

      &.calendar-picker {
        &-startDate {
          label {
            margin-left: -60px;
          }
        }

        &-endDate {
          label {
            text-align: right;
            width: 100%;
          }
        }
      }

      #date-picker-end-date,
      #date-picker-start-date {
        max-width: 60px;
      }
    }

    .MuiInput-formControl {
      border-radius: 1px;
      border: solid 1px $bar-grey;

      &.Mui-error.Mui-error {
        border: solid 1px $state-red;
      }
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      content: none;
    }

    .MuiInputBase-root.MuiOutlinedInput-root fieldset {
      height: 45px;
      top: -5px;
      border-color: $bar-grey;
    }

    label.MuiFormLabel-root.MuiInputLabel-root {
      font-family: $font;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #333333;
      transform: scale(1);

      .MuiInputLabel-animated {
      }
    }

    .input.MuiInputBase-input {
      padding-left: 10px;
      padding-right: 10px;
    }

    .MuiIconButton-root:hover {
      background: transparent;
    }

    .MuiTouchRipple-root {
      display: none;
    }

    .MuiInputBase-root .MuiIconButton-root {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .time-picker {
    height: 40px;
    //margin-top: 30px;
    width: 60px;
    margin-bottom: 0;

    label {
      margin: 0;
    }

    .MuiFormControl-fullWidth {
      border: solid 1px $bar-grey;
      height: 40px;
    }

    input {
      -webkit-box-shadow: none;
      margin-left: 1px;
      margin-right: 1px;
      background: transparent;
      font-size: 13px;
      padding: 0;
      text-align: center;
    }

    span.react-time-picker__inputGroup__leadingZero {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}