@import "../../../../core/ui/assets/css/variables";

.wrap-add-section {
    position: relative;

  .header {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 144px;
    height: 40px;
    margin: 0 8px;

    span,
    svg {
      z-index: 3;
    }

    &:after {
      position: absolute;
      content: '';
      width: 144px;
      top: 4px;
      border-radius: 3px;
      height: calc(100% - 8px);
      background-color: rgba(173, 181, 189, 0.25);

      /* Only for Internet Explorer 10 and 11 */
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        left: 8px;

        .empty {
          left: 0;
        }

      }
    }

    &:before {
      position: absolute;
      content: '';
      width: 160px;
      top: 0;
      height: 100%;
      background-color: #e6eaed;

    }
  }

  &.selected {
    .header {
      &:after {
        background-color: #4092f5;
      }

      span {
        color: #ffffff;
      }

      svg {
        rect {
          stroke: #FFFFFF;
        }

        path {
          fill: #FFFFFF;
        }
      }
    }
  }

  &.empty {
    .header {
      margin-left: 0;

      &:after {
        /* Only for Internet Explorer 10 and 11 */
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          left: 0;
        }
      }


      &:before {
        margin-left: 24px;
      }
    }
  }
}

.add-product-popover {
  &--wrap {
    padding: 16px 20px;

    input:focus::-webkit-input-placeholder {
      color: transparent !important;
    }

    /* Firefox < 19 */
    input:focus:-moz-placeholder {
      color: transparent !important;
    }

    /* Firefox > 19 */
    input:focus::-moz-placeholder {
      color: transparent !important;
    }

    /* Internet Explorer 10 */
    input:focus:-ms-input-placeholder {
      color: transparent !important;
    }

    .search-wrap {
      max-width: 180px;
      margin-bottom: 8px;
      height: 40px;

      &.active {
        border: 1px solid $blue;
      }
    }


        .subheader{
            height: 20px;
            font-family: $font;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            color: $text;
            text-transform: uppercase;
            padding: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

    .list-item {
      min-height: 32px;
      padding: 0;
    }

    .text-item {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      color: $text;

      span {
        letter-spacing: normal;
        font-family: $font;
        font-size: 14px;
        line-height: 14px;
      }
    }

    .MuiListItemIcon-root {
      min-width: 24px;
      margin-right: 8px;
    }
  }

  .footer {
    border-top: 1px solid #adb5bd;
    margin-top: 8px;
    padding-top: 14px;

    a {
      height: 32px;
      font-family: $font;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.29;
      letter-spacing: normal;
      color: $text;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      svg {
        margin-right: 8px;
      }
    }
  }
}