.MuiDialogTitle-root {
  padding: 2px 5px !important;
  text-align: right;
}

.dialog-popup {
  border: none;
  border-radius: 4px;
  box-shadow: 0 4px 20px 0 rgba(33, 37, 41, 0.2), 0 1px 7px 0 rgba(0, 0, 0, 0.15);
  padding: 16px 32px 24px;
  background-color: #fff;
  min-width: 388px;

  h2 {
    text-align: center;
    font-size: 20px;
  }

  > p, > div {
    text-align: center;
  }

  button.MuiButton-root.delete-btn {
    color: red;
  }
}
