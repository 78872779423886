.multiselect-checkbox-paper {
    
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #4092f5;
    }

    .MuiTypography-body1 {
        font-size: 0.9rem;
    }

    .MuiFormControl-root {
        margin-bottom: 0;
    }
}