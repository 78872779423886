@import './src/core/ui/assets/css/variables';

.wrap-alert-sidebar {
    width: 336px;

    .header {
        font-family: $font;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: $text;
        margin: 4px 0 29px;
    }

    .body {
        margin: 0 -32px;
        height: calc(100vh - 138px);
        //overflow: hidden scroll;
        //scroll-behavior: smooth;
    }

    .read-all {
        display: flex;
        justify-content: flex-end;
        margin-block: 5px;
        position: absolute;
        width: 100%;
        right: 25px;
        top: 63px;

        &-btn {
            font-size: 12px;
            moz-box-align: baseline;
            align-items: baseline;
            border-width: 0px;
            border-radius: 3px;
            box-sizing: border-box;
            display: inline-flex;
            font-style: normal;
            font-family: inherit;
            font-weight: 500;
            max-width: 100%;
            position: relative;
            text-align: center;
            text-decoration: none;
            transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
            white-space: nowrap;
            background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
            color: #4092f5;
            cursor: pointer;
            height: auto;
            line-height: inherit;
            padding: 0px;
            vertical-align: baseline;
            width: auto;
            -moz-box-pack: center;
            justify-content: center;

            &:hover {
                background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
                color: #4092f5;
                text-decoration: underline;
                transition-duration: 0s, 0.15s;
            }
        }
    }

}

.right-sidebar.alert {
    div[role="presentation"] {
        min-width: 336px;
    }
}

.loading {
    background: linear-gradient(-45deg, $grey, #fff);
    background-size: 400% 400%;
    -webkit-animation: gradient 2s ease infinite;
    -moz-animation: gradient 2s ease infinite;
    -o-animation: gradient 2s ease infinite;
    animation: gradient 2s ease infinite;
    display: flex;
    min-height: 100px;
    height: 100%;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    color: $text-grey;
    align-items: center;
    justify-content: center;
    position: relative;
}

/* Анимация */
@-webkit-keyframes gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

@-o-keyframes gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}