@import '../../../../core/ui/assets/css/variables';

.histogram-settings-form {

    h3 {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        padding: 7px 0;
        letter-spacing: normal;
        margin-bottom: 16px;
        max-width: 340px;
    }

    .MuiFormLabel-root,
    .MuiFormControlLabel-label {
        font-family: $font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
    }

    .MuiGrid-root {

        .form-field, .form-fields,
        .MuiTextField-root {
            width: 152px;
        }
    }

    .MuiIconButton-root.MuiRadio-root {
        padding: 8px;
    }

    .button-row {
        justify-content: center;
        margin-top: -14px;

        .MuiButton-containedPrimary {
            margin-right: 20px;
        }
    }

    .color-picker {
        max-width: 336px;
    }
}