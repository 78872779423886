.color-picker {
    margin-bottom: 23px !important;

    .MuiSvgIcon-root {
        height: 32px;
        width: 32px;
    }

    .icon {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: block;

        &.checked {
            box-shadow: inset 0 0 0 3px #4092f5;
        }
    }
}