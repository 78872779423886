$leftSectionWidth: 320px;
$paddingLeft: 30px;

.wrap-hr {
  display: flex;
  flex-direction: column;
  position: relative;

  .padding {
    &-0 {
      padding-left: 8px;
    }

    &-1 {
      padding-left: $paddingLeft;
    }

    &-2 {
      padding-left: $paddingLeft * 2;
    }

    &-3 {
      padding-left: $paddingLeft * 3;
    }
  }

  .title-block {
    width: $leftSectionWidth;
    float: left;
    display: flex;
    align-items: center;
    line-height: 25px;
    border-bottom: 1px solid #e9ecef;
    background: #fff;
    user-select: text;

    &.title-block {
      user-select: none;
    }

    &-wrap {
      position: relative;
      //height: 40px;
    }
  }

  .right-section-hr {
    height: 40px;
    flex: 1;
    cursor: default;
  }

  .left-section-hr {
    width: $leftSectionWidth;
    align-items: center;
    display: flex;
    height: 40px;
    border-bottom: 1px solid #e9ecef;
    background: #fff;
    user-select: text;

    svg {
      margin-right: 8px;

      &.dropdown-arrow {
        margin-right: 0;
      }
    }

    .wrap-edit-menu-node {
      display: flex;
      left: 308px;
    }

    .wrap-edit-menu-node.active {
      .config-dots {
        visibility: visible;
      }
    }

    &:hover {
      .wrap-edit-menu-node {
        .config-dots {
          visibility: visible;
        }
      }
    }
  }

  .expansion-panel-hr {
    box-shadow: none;
    background: none;

    .dropdown-arrow {
      left: 6px;
      height: 40px;
      transform: rotate(-90deg);
    }

    &.Mui-expanded {
      margin: 0;

      .dropdown-arrow {
        transform: rotate(0deg);
      }
    }

    .panel {
      &-title {
        flex-direction: row-reverse;
        min-height: 40px;
        padding: 0;

        & > div {
          margin: 0;
          padding: 0;
        }
      }

      &-detail {
        padding: 0;
        flex-direction: column;
      }
    }

    .MuiAccordionDetails-root {
      display: block;
    }
  }
}
