@import '../../assets/css/variables.scss';

.MuiButtonBase-root.MuiButton-root,
.btn {
	font: 400 16px/1.5 $font;
	background: none;
	color: $blue;
	min-width: 154px;
	padding: 11px;
	box-shadow: none;
	outline: none;
	text-transform: none;

	&:hover {
		box-shadow: 0 0 0 1px $blue;
		background: none;
		outline: none;
		color: $blue;
	}

	&:focus {
		outline: none;
	}

	&.Mui-disabled {
		opacity: 0.5;
		background: none;
		color: $blue;
	}

	.MuiButton-label {
		z-index: 1;
	}

	.MuiTouchRipple-root {
		* {
			background-color: rgba(64, 146, 245, 0.1);
		}
	}

	&.MuiButton-sizeSmall {
		font-size: 12px;
		padding: 3px 10px;
		min-width: 96px;
		letter-spacing: 0.15px;
	}


	&.MuiButton-containedPrimary {
		background: #fff;
		color: #333333;
		border: solid 1px rgba(0, 0, 0, 0.15);
		font-weight: 400;
		box-shadow: 0 1px 1px 0 rgba(204, 204, 204, 0.4);

		&:hover {
			background: #fff;
			border: solid 1px rgba(0, 0, 0, 0.25);
			box-shadow: 0 1px 1px 0 rgba(204, 204, 204, 0.4);
			color: #333333;
		}

		&.Mui-disabled {
			opacity: 0.4;
			background: #fff;
			color: #333333;
			border: solid 1px rgba(0, 0, 0, 0.2);
			box-shadow: 0 1px 1px 0 rgba(204, 204, 204, 0.2);
		}

		.MuiTouchRipple-root {
			* {
				background-color: rgba(0, 0, 0, 0.15);
			}
		}
	}

	&.MuiButton-containedSecondary {
		background-color: $blue;
		color: #fff;
		border-color: #3579cc;
		box-shadow: 0 1px 1px 0 rgba(204, 204, 204, 0.4);

		&:hover {
			border: solid 1px rgba(0, 0, 0, 0.1);
			box-shadow: 0 1px 1px 0 rgba(204, 204, 204, 0.4);
			color: #fff;
		}

		.MuiTouchRipple-root {
			* {
				background-color: #3983dd;
			}
		}
	}

	&.MuiButton-outlinedSecondary {
		border: solid 1px $blue;
	}

	&.btn-gray {
		background: #dee1e6;
		border-color: #dee1e6;
	}

	&.btn-delete {
		.MuiButton-label {
			color: #cc1431;
		}
	}
}