.parameter-body {
    .parameter-section {
        padding:  0 17px 17px;
        border-bottom: 1px solid #E9ECEF;

        &.hmi-opacity {
            padding:  0 27px 17px;

            .parameter-section-title {
                margin-left: -8px;
            }
        }

        &:not(:first-child) {
            padding-top: 17px;
        }
        &:last-child {
            border-bottom: none;
            padding-bottom: 10px;
        }

        &-title {
            font-size: 12px;
            line-height: 20px;
            font-style: normal;
            font-weight: normal;
            color: #74797D;
            //
            //@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: portrait){
            //    font-size: 19px;
            //    margin-bottom: 10px;
            //}
        }

        &.show-hmi {
            .parameter-section-body {
                .MuiFormGroup-root {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    label {
                        width: 50%;
                    }
                }
                .field-checkbox {
                    max-width: 137px;
                    max-height: 96px;
                    position: relative;
                    padding: 10px;
                    margin: 0;
                    border-radius: 6px;
                    border: 1px solid #ADB5BD;

                    &:first-child {
                        margin-right: 12px;
                    }

                    &.checked {
                        border: 1px solid #4092F5;

                    }

                    span.MuiRadio-root {
                        position: absolute;
                        top: 0;
                        left: -1px;
                    }
                }

                .wrapper-icon {
                    display: flex;
                    flex-direction: column;
                    .label-title {
                        padding-left: 35px;
                        font-size: 12px;
                        line-height: 20px;
                        vertical-align: super;
                        position: relative;

                        //@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: portrait){
                        //    font-size: 17px;
                        //    margin-bottom: 10px;
                        //}

                        &-icon {
                            svg {
                                max-width: 115px;
                            }
                        }
                    }
                }
            }
        }

        .MuiSvgIcon-root,
        .checkbox-icon {
            width: 20px;
            height: 20px;
        }

        .show-hmi {

        }

        &.show-object {
            padding-bottom: 0;
        }

        .hmi-opacity {

        }
    }

    .MuiSlider-thumb {
        .MuiSlider-valueLabel {
            top: calc(100% + 10px) !important;
            white-space: nowrap;
            //
            //@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: portrait){
            //    font-size: 15px;
            //}
        }
    }

    .MuiFormControlLabel-label {
        font-size: 12px;
        line-height: 16px;

        //@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: portrait){
        //    font-size: 17px;
        //    line-height: 20px;
        //}
    }
}
.only-mobile-preferences.parameter-body {

    .parameter-section {
        &-title {

            @media only screen and (orientation: portrait){
                font-size: 19px;
                margin-bottom: 10px;
            }
        }
        &.show-hmi {
            .parameter-section-body {
                .wrapper-icon {
                    .label-title {
                        @media only screen and (orientation: portrait){
                            font-size: 17px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    .MuiSlider-thumb {
        .MuiSlider-valueLabel {
            @media only screen and (orientation: portrait){
                font-size: 15px;
            }
        }
    }

    .MuiFormControlLabel-label {

        @media only screen and (orientation: portrait){
            font-size: 17px;
            line-height: 20px;
        }
    }
}