@import "./src/core/ui/assets/css/variables";

.hidden {
    visibility: hidden;
    opacity: 0;
}

.header-structural-tree {
    display: flex;
    flex-direction: row;
    //overflow: hidden;

    .header-structural-tree-left {
        min-height: 122px;
    }

    &.minimapVisible {

        .header-structural-tree-left {
            min-height: 57px;
            max-height: 57px;
        }

        .hide-sidebar {
            top: 5px;
        }
    }

    .hide-sidebar {
        position: absolute;
        top: 35px;
        padding: 0;
        background: #FFFFFF;
        z-index: 10;
        color: rgba(0, 0, 0, 0.54);

        &.MuiCheckbox-colorSecondary.Mui-checked {
            color: rgba(0, 0, 0, 0.54);
        }


        &:hover {
            background: $blue !important;
            color: #FFFFFF;
            border-color: $blue;

            &.MuiCheckbox-colorSecondary.Mui-checked {
                color: #FFFFFF;
            }

            //background: #FFFFFF !important;
        }

        span.MuiIconButton-label {
            border: 1px solid #e9ecef;
            border-radius: 100%;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.1);
        }

        &.edit-page {
            span.MuiIconButton-label {
                background: rgba(255, 255, 255, 0.7);
            }
        }
    }

    .btn-group {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        flex: 1;
        z-index: 2;

        &.hidden {
            visibility: visible;
            opacity: 1;
            margin-left: -12px;
        }

        .editMode,
        .monitoringMode {
            padding: 6px;
        }

        .editMode.MuiIconButton-root.Mui-disabled {
            opacity: 0.5;
        }

        .MuiIconButton-colorSecondary:hover,
        .MuiCheckbox-colorSecondary.Mui-checked:hover {
            background-color: transparent;
        }

        svg {
            cursor: pointer;
        }
    }

    .search-group {
        z-index: 2;
    }

    &-left {
        background: #FFFFFF;
        max-width: 320px;
        display: inline-flex;
        flex-direction: row;
        padding: 15px 12px 0 12px;
        width: 100%;
        z-index: 1;
        max-height: 90px;
        align-items: flex-start;
        border-right: 1px solid #e9ecef;
    }

    &-right {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        //width: calc(100% - 320px);
        z-index: 4;
        padding-bottom: 27px;
        //position: relative;

        &.hmi-full-screen {
            z-index: 20;
        }
    }
}

.structural-tree {
    &-wrap {
        background: #e6eaed;
        // height: 100%;
        flex-grow: 1;
        position: relative;
        //overflow: hidden;

        &:focus,
        &:hover,
        &:active {
            outline: none;
        }

        .header-structural-tree {
            &:before {
                content: '';
                position: absolute;
                top: 122px;
                width: 320px;
                left: 0;
                z-index: 2;
                height: 1px;
                background: #e9ecef;
            }

            &.minimapVisible {
                &:before {
                    top: 68px;
                }
            }
        }

        .breadcrumb-text {
            user-select: text;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            z-index: 2;

            &.is-mobile {
                user-select: none;
            }

            & > div {
                width: 87%;
                direction: rtl;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    /* IE10+ specific styles go here */
                    text-overflow: clip;
                }

                &:before {
                    content: '';
                    display: block;
                }
            }
        }

        .message.visible {
            max-width: 320px;
        }

        &.close-sidebar {
            .header-structural-tree {
                &:before {
                    width: 77px;
                }
            }

            .message.visible {
                max-width: 76px;
            }

            .right-section {
                .sensor-name {
                    direction: ltr;
                    z-index: 1;
                }
            }
        }
    }
}

.first-item-on-tree {
    .item-title:first-child {
        .left-section.product-line {
            //padding-top: 6px;
        }
    }
}

.structure-tree {
    position: relative;
    z-index: 1;
    width: 100%;

    .right-section {
        &.limit-padding {
            min-width: 76px;
            max-width: 76px;
        }

        &-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -moz-box;
            -moz-box-orient: vertical;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            box-orient: vertical;
            padding-right: 15px;

            &.true {
                -webkit-line-clamp: 2;
                line-clamp: 2;
            }

            &.false {
                -webkit-line-clamp: 5;
                line-clamp: 5;
            }
        }
    }

    .limit-padding {
        padding: 0 12px 0 46px !important;
        color: transparent;
        background: #FFFFFF;
        border-top: none !important;

        .sensor-name {
            position: absolute;
            left: 100%;
            z-index: 23;
            color: $text;
            width: max-content;
            font-family: $font;
            font-size: 10px;
            font-weight: 600;
            font-stretch: normal;
            min-width: 320px;
            font-style: normal;
            padding-left: 8px;
            letter-spacing: normal;
            pointer-events: none;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .factory.open:first-child {
        border-top: 1px solid #e9ecef;
    }

    .wrap-section {
        display: flex;
        width: 100%;

        &:focus,
        &:hover,
        &:active {
            outline: none;
        }
    }

    .left-section {
        width: 100%;
        //min-width: 100%;
        //width: calc(100% - 320px);
        display: inline-flex;

        svg,
        .state-chart {
            flex: 1;
            touch-action: manipulation;
        }

        &.no-chart {

            &:focus,
            &:hover,
            &:active {
                outline: none;
            }

            svg {
                display: none;
            }
        }

        &.with-chart {
            background: #FFFFFF;
            height: 40px;

            &.skeleton {
                border-bottom: 1px solid rgb(233, 236, 239);
                &>div {
                    display: flex;
                    width: 100%;

                    //span {
                    //    width: 100%;
                    //}
                }
            }

            & > span {
                width: 100%;
            }

            &:focus,
            &:hover,
            &:active {
                outline: none;
            }
        }

        &.product-scheme {
            font-family: $font;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.67;
            letter-spacing: normal;
            color: $text;
            //padding-left: 8px;
        }

        &.product-line {
            //padding-top: 6px;
            background: #e6eaed;
        }
    }

    .right-section {
        height: 40px;
        max-width: 320px;
        min-width: 320px;
        width: 100%;
        display: inline-flex;
        flex: 1;
        align-items: center;
        border-bottom: 1px solid #e9ecef;
        border-right: 1px solid #e9ecef;
        position: relative;
        cursor: pointer;
        background: #FFFFFF;

        .sensor-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 12px;
            user-select: text;

            &.is-mobile {
                user-select: none;
            }

            .rtl {

                direction: rtl;
            }

            &.edit {
                direction: ltr;
            }
        }

        .right-section-text {
            &.is-mobile {
                user-select: none;
            }
        }

        .MuiCheckbox-root {
            padding: 6px;
            z-index: 2;
        }

        &.desktop {

            .dropdown-arrow {
                transition: ease 0.35s;
            }
        }

        .dropdown-arrow {
            transform: rotate(-90deg);
            min-width: 24px;
            overflow: hidden;
            max-width: 24px;
            z-index: 1;
            position: relative;

        }

        &.active {
            .dropdown-arrow {
                transform: rotate(0);
            }
        }
    }

    .key-param {
        position: absolute;
        left: 80px;
        top: 50%;
        transform: translate(0, -50%);
    }

    .factory-item {
        user-select: none;
        z-index: 1;

        .MuiCollapse-wrapper {
            height: 100%;
        }
    }

    .product-scheme {
        position: static;
        display: inline-flex;
        align-items: flex-end;
    }

    .process-item {
        position: relative;
        overflow: hidden;
        z-index: 1;
    }

    .product-scheme-title {
        color: $blue;
        font-weight: 600;
        font-style: italic;
        line-height: 1.43;
        display: flex;
        align-items: flex-end;
        align-self: stretch;
        padding: 20px 0 0 5px;

        &:after {
            content: '';
            position: absolute;
            top: 30px;
            bottom: 0;
            margin: 0 0 1px -8px;
            transform: translate(-100%);
            width: 12px;
            border-width: 1px 0 1px 1px;
            border-color: $blue;
            border-style: solid;
            left: 69px;
            z-index: 1;
        }
    }

    .item-title {
        width: 100%;

        .display-flex {
            display: inline-flex;
        }

        .sensor-name.is-mobile {
            user-select: none;
        }

        &:focus,
        &:hover,
        &:active {
            outline: none;


        }


        &.editPreferences,
        &.editPreferences:hover {
            .right-section {
                &.sensor-name {
                    background: #4092f5;
                    color: #FFFFFF;
                }
            }

        }

        &.hover,
        &:hover {
            .right-section {
                &.sensor-name {
                    background: rgba(64, 146, 245, 0.2);
                }
            }
        }

        &.open-hmi:not(.hover):hover {
            .right-section {
                &.sensor-name {
                    background: inherit;

                }
            }
        }

        &.no-pointer {
            display: flex;
            width: 100%;

            .right-section {
                cursor: unset;
            }
        }

        .sensor-item.no-pointer {
            height: 40px;
            display: flex;
            width: 100%;

            .right-section {
                cursor: unset;
            }
        }

        //.left-section {
        //    //width: calc(100vw - 340px);
        //}
    }

    &:not(.monitoring-mode) {
        .rule {
            .time {
                top: 0;
            }

            .line {
                height: calc(100% - 28px);
                margin-top: 28px;
            }
        }
    }

    &.monitoring-mode {
        margin-top: 0;

        .key-param {
            left: 15px;
        }

        .right-section.breadcrumb {
            padding-left: 12px;
            color: #74797d;
            justify-content: space-between;
            align-items: flex-end;
            padding-top: 18px;
            z-index: 5;

            &.small-height {
                max-height: 14px;
                font-size: 14px;
                padding-top: 0;
                padding-bottom: 0;
                border-right: none;
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    height: 19px;
                    width: 1px;
                    right: 0;
                    bottom: -1px;
                    background: #e9ecef;
                }

                .breadcrumb-text > div {
                    max-height: 17px;
                    line-height: 14px;
                    font-size: 12px;
                }
            }

            &.first-child {
                height: 26px;
                padding-top: 0;
                max-height: 26px;
                overflow: hidden;

            }

            span {
                font-family: $font;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                //text-transform: capitalize;

                &:last-child {
                    font-weight: bold;
                    color: $text;
                }
            }
        }
    }

    .extended {

        .left-section.with-chart {
            height: 110px;
        }

        height: 110px;

        .right-section {
            height: 110px;
        }

        //.item-title {
        //
        //    .sensor-item.no-pointer {
        //    }
        //}
    }

    .placeholder-props {
        background: transparent;
        width: 100%;
        border-top: 2px solid #699be4;
        z-index: 2;

        &:before {
            z-index: 2;
            content: '';
            position: absolute;
            left: 0;
            display: block;
            width: 6px;
            height: 6px;
            background: #FFFFFF;
            border: solid 2px #699be4;
            border-radius: 100%;
            top: -4px;
        }
    }
}

.state-details {
    width: auto;
    max-width: 400px;
    min-width: 360px;
    display: table;

    .fill {
        width: 6px;
        display: table-cell;
    }

    .text {
        padding: 12px 20px 12px 14px;
        display: table-cell;

        .title {
            margin-bottom: 5px;

            .category {
                font-size: 13px;
                font-weight: 600;
            }

            .reason {
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.15;
                letter-spacing: 0.77px;
            }
        }

        .comment {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.25;
            letter-spacing: 0.68px;
            margin-bottom: 10px;
            max-width: 320px;
            word-break: break-all;
            max-height: 360px;
        }

        .duration {
            font-size: 11px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.27;
            letter-spacing: 0.49px;
            margin-bottom: 4px;

            img {
                display: inline;
                vertical-align: top;
            }
        }

        .comment-author {
            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.27;
            letter-spacing: 0.49px;
            margin-bottom: 10px;

            img {
                display: inline;
                vertical-align: middle;
                max-width: 12px;
            }
        }

        .times {

            .start {
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.27;
                letter-spacing: 0.49px;
                color: #9b9b9b;

                img {
                    display: inline;
                    vertical-align: middle;
                }
            }

            .end {
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.27;
                letter-spacing: 0.49px;
                color: #9b9b9b;
                margin-left: 14px;

                img {
                    display: inline;
                    vertical-align: middle;
                }
            }
        }
    }
}

.alert-details {
    display: flex;
    width: 400px;

    .alert-details-wrap {
        padding: 12px 20px 12px 14px;
        width: 100%;
    }

    .alert-item--path {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .comment {
        margin-bottom: 8px;
        font: $font;
        color: $text
    }
}


.structural-tree-wrap {
    .header-structural-tree {
        display: flex;
        flex-direction: row;
        position: fixed;
        z-index: 2;
        height: 100%;
        width: 100%;
        pointer-events: none;
    }
}


.hide-sidebar,
.panel,
.mini-map,
.header-structural-tree-left,
.structural-tree-slider {
    pointer-events: all;

    &.pointer-event-none {
        pointer-events: none;
    }
}

.structural-tree-slider {
    //position: relative;
    z-index: 1;
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    position: fixed;

    &.minimap-visible.desktop {
        margin-top: 123px;
        height: calc(100% - 163px);
    }

    &.minimap-invisible.desktop {
        margin-top: 68px;
        height: calc(100% - 100px);
    }

    &.mobile.minimap-visible {
        .structure-tree {
            margin-top: 122px;
        }
    }

    &.minimap-visible {
        //height: calc(100vh - 155px);

    }

    &.mobile.minimap-invisible {
        .structure-tree {
            margin-top: 67px;
        }
    }

    &.minimap-invisible {
        //height: calc(100vh - 100px);

    }

    &.scrollTop-hidden {
        overflow-y: hidden;
    }

    &.scrollTop-scroll {
        overflow-y: scroll;
    }

    & > div:last-child {
        z-index: 2;
    }

    &.is-ios {
        @media screen and (max-width: 965px) {
            //height: calc(100vh - 220px);
        }
    }

    &.is-android {
        @media screen and (max-width: 965px) {
            //height: calc(100vh - 220px);
        }
    }

    &.is-android,
    &.is-ios {
        @media screen and (orientation: landscape) {
            //height: calc(100vh - 120px);
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        overflow-y: auto !important;
    }

}
