input,
textarea,
select {
	color: #333333;
}

textarea {
	min-height: 142px;
	z-index: 1;
}

input,
input:-internal-autofill-selected,
input:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	-webkit-box-shadow: inset 0 0 0 50px #fff;
	margin-left: 1px;
	margin-right: 1px;
	background: #fff;
}

::-webkit-input-placeholder {
	color: #adb5bd;
	font-size: 14px;
}

::-moz-placeholder {
	color: #adb5bd;
	font-size: 14px;
}

:-ms-input-placeholder {
	color: #adb5bd;
	font-size: 14px;
}

:-moz-placeholder {
	color: #adb5bd;
	font-size: 14px;
}

textarea::-webkit-input-placeholder {
	font-style: italic;
}

textarea::-moz-placeholder {
	font-style: italic;
}

textarea:-ms-input-placeholder {
	font-style: italic;
}

textarea:-moz-placeholder {
	font-style: italic;
}

.label-inline {
	display: flex;
	align-items: center;

	label {
		width: 116px;
		margin: 0;
		padding: 0 10px 0 0;
	}

	.MuiFormControl-root {
		flex-grow: 1;
		width: auto;
	}
}

.row-range {
	display: flex;
	align-items: center;

	@media(max-width: 480px) {
		flex-direction: column;
	}

	.cell {
		width: 100%;
	}

	.separator {
		margin: 0 16px;
		width: 8px;
		min-width: 8px;
		height: 1px;
		background: #333333;

		@media(max-width: 480px) {
			margin: 10px 0;
		}
	}

	.form-field {
		margin: 0;
	}
}

.validation-massage {
	color: #ff3b30;
	margin: 3px 0 0;
	font-size: 12px;
	text-align: left;

	&::first-letter {
		text-transform: uppercase;
	}
}

.form-box {
	text-align: center;

	form {
		max-width: 360px;
		margin: 0 auto;
	}

	.btn.MuiButton-root {
		min-width: 232px;

	}
}

.form-box-space {

	.form-field,
	.form-fields {
		margin: 0 0 16px;
	}

	.btn {
		margin: 16px 0 0;
	}
}

.button-row {
	display: flex;
	justify-content: space-between;
	align-items: center;

	a + button {
		margin: 0 0 0 5px;
	}

	.btn.MuiButton-root {
		min-width: 152px;
	}
}

.button-row.flex-end {
	justify-content: flex-end;

	button {
		margin: 0 0 0 10px;
	}

	&.space-2x {
		button {
			margin: 0 0 0 16px;
		}
	}
}

.link-box {
	margin: 23px 0 0;
}

.form-intro {
	font-size: 18px;
	margin: 0 0 30px;
}

h2.form-header-heavy {
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.67;
	letter-spacing: normal;

	span {
		font-weight: normal;
	}
}

.tip {
	border-radius: 1px;
	background-color: #fff4ba;
	padding: 10px;
	font-style: italic;
	width: 100%;
}

.row-subheader {
	padding: 15px 0;
}

.row-wide {
	min-width: 610px;
}

.red-btn {
	color: #eb444b !important;
}

.form-fields-inline {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 47px;

	&.left {
		justify-content: flex-start;
	}
}

.MuiCheckbox-colorPrimary.Mui-checked {
	color: $blue !important;
}


.checkbox-icon {
	display: inline-block;
	width: 26px;
	height: 26px;
	background: url(../images/icons/off.svg) center no-repeat;
	background-size: contain;
}