.beacon-edit-form {
    .form-field.info-field {
        margin: 40px 0;

        p {
            width: 100%;
            margin-bottom: 10px;
            line-height: 1rem;
        }
    }

    .select-box .placeholder {
        color: #333333;
    }

    fieldset {
        width: 100%;
    }
}
