.map-editor-menu {
  width: 100%;
  background-color: #e6eaed;
  padding: 5px;
  display: flex;
  align-items: center;

  .MuiIconButton-colorPrimary {
    max-width: 50px;
    max-height: 50px;
  }

  .panel-label {
    padding: 5px 5px 5px 5px;
    font-size: 12px;
  }

  .panel-separator {
    width: 1px;
    height: 24px;
    background-color: #cdd0d4;
    margin: 12px 20px 0px;
  }

  .tool {
    width: auto;
    height: 24px;
    padding: 3px;
    box-shadow: inset 1px 1px 2px #ffffff, 1px 1px 1px rgba(0, 0, 0, 0.3);
    background-color: #e6eaed;
    cursor: pointer;
    border-radius: 3px;
    margin: 0 10px 0 0;

    &.selected {
      background-color: #eeeeee;
      box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.4), 1px 1px  0px #ffffff;
    }
  }
}
.editor-menu-dropdown {
  .editor-menu-dropdown-list-item {
    padding: 6px 27px;
    span.MuiTypography-body1 {
      font-family: "IBM Plex Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
    }
    &>div:first-child {
      max-width: 40px;
      min-width: 40px;
    }
  }
}