@import "../../../core/ui/assets/css/variables";

.edit-sensor-list {
  &.form-box-list-form {
    text-align: left;
    width: 100%;
    height: 40px;
    max-width: max-content;
    margin: 0 auto;
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid #e9ecef;

    form {
      height: 39px;
      display: inline-flex;

      &.target-form {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

  }

  .description {
    &__id {
      width: 70px;
      max-width: 70px;
      min-width: 70px;
    }

    &__pit-code,
    &__um {
      width: 86px;
      max-width: 86px;
      min-width: 86px;
    }

    &__comment {
      width: 192px;
      max-width: 192px;
      min-width: 192px;
    }

    &__value {
      width: 144px;
      max-width: 144px;
      min-width: 144px;
      padding-left: 15px;
    }

    &__targetvalue {
      width: 152px;
      min-width: 152px;
      max-width: 152px;
      text-align: right;
      align-self: stretch;
      display: flex;
      margin: 0;
      padding: 0 8px 0 0;

      &.form {
        //padding-right: 8px !important;
      }

      &:last-child,
      &.last-child {
        padding: 0;
        width: 144px;
        max-width: 144px;
        min-width: 144px;
      }

      .MuiTypography-root {
        width: 100%;
        display: flex;
        border-right: 1px solid #3f92f5;
        border-left: 1px solid #3f92f5;
      }
    }

    &__um,
    &__pit-code,
    &__comment {
      .value {
        padding-left: 15px;
        padding-top: 3.5px;
        padding-bottom: 3.5px;
        width: 100%;
      }
    }

    &__id,
    &__um,
    &__pit-code,
    &__comment,
    &__value {
      height: 100%;
      margin: 0;
      align-items: center;
      display: flex;
      background: #ffffff;
    }

    &__id {
      justify-content: center;
    }
  }

  .btn-group {
    .btn.MuiButton-root {
      min-width: 150px;
    }

    button:first-child {
      margin-right: 20px;
    }

  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    display: none;
  }

  .MuiInputBase-root.Mui-disabled {
    color: $text;
  }

  .form-field {
    margin: 0;
  }

  .MuiInputBase-root {
    height: auto;

    input {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 14px;
      font-weight: normal;
      font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      height: 37px;
      border: 2px solid #a6cfff;
      border-radius: 0;
      margin: 0;

      &::-ms-clear {
        display: none;
      }
    }

    fieldset {
      display: none;
    }
  }

  .target-list {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .target-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    padding: 0 15px;
    background: rgb(255, 255, 255);
    //border-bottom: 1px solid rgb(233, 236, 239);
    width: 50%;
    overflow: hidden;

    span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      z-index: 3 !important;
    }

    + .target-item {
      border-left: 1px solid #e9ecef;
    }

    &.active {
      padding: 0;
    }

    input {
      text-align: end;
      height: 36px;
      padding-top: 0;
      padding-bottom: 0;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .max-target-value {
      height: 40px;
    }
  }
}

.description {
  &__targetvalue {
    min-width: 152px;
    max-width: 152px;
  }
}