.node-form.hmi-form {
 .table-body {

   label.upload-hmi-label {
     font-weight: bold;
     font-size: 16px;
   }

   hr {
     size: 1px;
     margin: 0 0 20px 0;
   }

   .drop-upload-separator {
     text-align: center;
     color: #c4c6c7;
     margin-top: 20px;
   }

   .file-field {

     .btn.MuiButton-root {
       width: 100%;
     }
   }

   .cropper {
     height: 500px;
     width: 100%;
   }

   .preview-block {
     position: relative;

     ._loading_overlay_wrapper--active {
       height: 500px;
     }
   }
 }
}

.node-form.hmi-form.wide {

  form {
    width: 870px;
    max-width: 870px;

    .btn-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
    }
  }

  .cropper-move {
    cursor: grabbing;
  }

  .action-section {
    display: flex;

    .action-btn-list {
      flex: auto;
    
      .grab-tool {
    
        &.active {
          color: #4092f5;
        }

        .MuiIconButton-label {
          margin-left: -4px;
        }
      }

      .crop-tool {

        &.active {
          color: #4092f5;
        }
      }

      .reset-tool {
        &:active  {
          color: #4092f5;
          transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }
    }
    
    .slider {
      width: 50%;
      align-self: flex-end;
    
      .MuiSlider-rail {
        background-color: #4092f5;
        opacity: 1;
      }

      .MuiSlider-thumb {
        color: #4092f5;
      }
    }
  }
}