@import "./src/core/ui/assets/css/variables";

.edit-dots__menu-manufacturing {
    svg {
        margin-right: 8px;
    }
    .menu-item {
        font-family: $font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.29;
        letter-spacing: normal;
        color: $text;
        padding: 0 24px;
        &:hover {
            background-color: rgba(0, 0, 0, 0.08);
        }
    }
}