@import "../../../core/ui/assets/css/variables";

.node-form {
  .title-icon {
    min-height: 40px;
    min-width: 40px;
    margin-left: 15px;
  }
  &.form-box {
    text-align: left;

    form {
      max-width: 400px;
      margin: 0 auto;
      padding-bottom: 80px;
    }

    fieldset {
      legend {
        //display: none;
        width: 0 !important;
      }
    }

    .MuiOutlinedInput-root.Mui-disabled.select-box .MuiOutlinedInput-notchedOutline {
      max-height: 44px;
      top: -6px;
    }

    .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
      max-height: 40px;
      top: 0;

      legend span {
        display: none;
      }
    }
  }
  .MuiOutlinedInput-root,
  .select-box.MuiInputBase-root fieldset,
  .MuiSelect-outlined {
    border-radius: 1px;
  }

  .select-unit {
    margin-bottom: 16px;
  }

  .btn-group {

    .btn.MuiButton-root {
      min-width: 150px;
    }

    button:first-child {
      margin-right: 20px;
    }

  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    display: none;
  }
  .MuiSelect-select.MuiSelect-select {
    z-index: 2;
  }

  .title {
    font-family: $font;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: $text;
  }

  .sensor {
    margin-left: 8px;
    width: 24px;
    height: 22px;
    object-fit: contain
  }

  .table-header {
    display: flex;
    align-items: center;
  }

  .table-subheader {
    margin: 20px 0 16px;

    .current-options {
      background-color: #e9ecef;
      font-family: $font;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.86;
      letter-spacing: normal;
      color: $text;
      display: flex;
      justify-content: space-between;
      max-width: 210px;

      &__id {
        display: flex;
        padding: 0 15px;
      }

      &__value {
        display: flex;
        padding: 0 15px;
      }

      .value {
        font-weight: bold;
        padding-left: 9px;
      }
    }
  }
  .MuiTypography-body1 {
    font-family: $font;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: normal;
    color: $text;
    margin: 0;
  }
  .table-body {
    label {
      font-family: $font;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 40px;
      letter-spacing: normal;
      color: $text;
      margin: 0;
    }
  }
}