@import '../../core/ui/assets/css/variables';

.configuration-svg {
    .st0 {
        fill: #74797D;
    }
}

.MuiToolbar-root {
    &.MuiToolbar-regular {
        min-height: 40px;
    }

    .svg_alert-panel {
        height: 24px;
        width: 24px;
    }

    .MuiListItem-root {
        max-height: 40px;
        padding: 9px;
    }

    .svg_alert-panel {
        .st1,
        .st2 {
            fill: #333333;
        }
    }

    .menu-avatar {
        height: 24px;
        width: 24px;
        //background: url("../../core/ui/assets/images/user-icon.png") no-repeat;
        border-radius: 100%;
    }

    .MuiListItem-button:hover {
        text-decoration: none;
        background-color: rgba(#4092f5, 0.08);
    }

    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
        background-color: #4092f5;

        .svg_setting {
            .color {
                fill: #ffffff;
            }
        }

        .svg_alert-panel {
            .st1,
            .st2 {
                fill: #ffffff;
            }
        }
    }

}

.header-custom-style {
    display: flex;
    margin-bottom: 40px;

    &.visible-hmi {
        z-index: 1;
    }
}

.right-navigation {
    width: 100%;
    display: inline-flex;
    flex-direction: row;

    .ScrollbarsCustom-Content{
      display: inline-flex !important;
        .MuiListItem-root:first-child {
            width: auto;
            min-width: 100px;
            .MuiListItemText-primary {
                visibility: visible;
                width: 100%;
                opacity: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .MuiListItem-root {
            min-width: 100px;
        }
        .MuiListItem-root.Mui-selected {
            min-width: 160px;
        }
    }
    .ScrollbarsCustom-ThumbX{
        background: rgba(64, 146, 245, 0.4) !important;
    }
    .ScrollbarsCustom-TrackX{
        background: transparent !important;
        height: 8px !important;
    }
    .ScrollbarsCustom-ThumbX{
        margin-top: 3px;
        height: 5px !important;
    }

   .ScrollbarsCustom-Wrapper {
        bottom: 0 !important;
    }

    & > .MuiListItem-root {
        //width: inherit;
    }

    .MuiListItem-root:first-child {
        background-color: #e9ecef;
        width: 42px;
        min-width: 42px;

        .MuiListItemText-primary {
            margin-left: 6px;
            width: 0;
            visibility: hidden;
            opacity: 0;
            display: inline-block;
            transition: width ease 0.35s;
        }
    }

    .MuiListItem-root {
        border-right: 1px solid #e6eaed;
        flex-shrink: 1;
        white-space: nowrap;
        //min-width: 0;
        width: auto;
        max-width: 160px;

        .MuiListItemText-primary {
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.86;
            letter-spacing: normal;
            font-family: $font;
        }
    }

    .MuiListItem-root:last-child {
        border-right: none;
        min-width: 50px;
    }

    .MuiListItem-root.Mui-selected:first-child {
        min-width: 160px;
    }

    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
        background-color: #4092f5;
        //width: inherit;

        .MuiListItemText-primary {
            color: #ffffff;
        }

        &:first-child {
            .configuration-svg {
                .st0 {
                    //fill: #74797D;
                    fill: #ffffff;
                }
            }

            .MuiListItemText-primary {
                opacity: 1;
                visibility: visible;
                width: 100%;

            }
        }
    }

    .add-panel {
        height: 38px;
        width: 38px;

        .st1 {
            fill: transparent;
        }
    }

    .MuiListItem-root.dashboard-page {
        background-color: #fff;
        padding-right: 40px;
        position: relative;
        outline: none;

        .MuiListItemText-primary {
            font-weight: normal;
            color: #000;
            margin-right: 10px;
        }

        // like a chrome
        &.like-chrome {
            padding: 9px;

            span.MuiTypography-body1 {
                //color: transparent;
            }

            .action-menu {
                right: 0;
            }
        }
    }

    .MuiListItem-root.dashboard-page.Mui-selected,
    .MuiListItem-root.dashboard-page.Mui-selected:hover {

        background-color: rgba(#4092f5, .2);
        //background-color: #fff;
        border-bottom: 3px solid #4092f5;

        .MuiListItemText-primary {
            font-weight: 600;
            color: #000;
        }
    }

    // like a chrome
    .MuiListItem-root.dashboard-page.like-chrome.Mui-selected,
    .MuiListItem-root.dashboard-page.like-chrome.Mui-selected:hover {
        min-width: 40px;
        background-color: #fff;
        border-bottom: 3px solid #4092f5;

        .MuiListItemText-primary {
            font-weight: 600;
            color: #000;
        }

        .action-menu {
            right: 6px;
        }
    }

    .MuiListItemText-root {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
#user_nav {
    .menu-item {
        font-family: $font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 32px;
        letter-spacing: normal;
        color: $text;
        padding: 0 24px;
    }
    .MuiList-padding{
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.right_nav {
    z-index: 3;
    ul {
        display: flex;
        flex-direction: row;
    }

    .MuiListItem-gutters:hover {
        background-color: rgba(64, 146, 245, .08);
    }
}

