.phone-field.form-field {
    margin-top: 56px !important;

    &.error-field {
        input {
            border: 1px solid red;
        }

        &:after {
            content: '\e964';
            color: #ff3b30;
            font-family: 'icomoon';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            position: absolute;
            right: 0px;
            top: 0;
            bottom: 0px;
        }
    }

    &.success-field {
        input {
            border: 1px solid #72c26a;
        }

        &:after {
            content: '\e973';
            color: #72c26a;
            font-size: 18px;
            font-family: 'icomoon';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            position: absolute;
            right: 0px;
            top: 0;
            bottom: 0px;
        }
    }
}

.react-tel-input .country-list {
    width: 340px;
}

.react-tel-input .form-control:focus {
    border-color: rgb(202, 202, 202);
    box-shadow: none;
}

.react-tel-input {
    max-height: 40px;

    .special-label {
        left: 0;
        top: -40px;
        line-height: 40px;
        font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #333333;
    }

    input.form-control {
        padding-top: 10.5px;
        padding-bottom: 10.5px;
        width: 100%;
        border-radius: 0;
    }

    .special-label {

    }
}