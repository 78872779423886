@import "../../assets/css/variables";

.message {
    max-height: 0;
    //transition:max-height 0.3s;
    overflow: hidden;
    &.visible {
        max-height: 100%;
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-sizeSmall, .btn.MuiButton-sizeSmall {
        max-height: 18px;
        padding: 0 10px;
    }
    .MuiButtonBase-root.MuiButton-root .MuiButton-label, .btn .MuiButton-label {
        font-size: 14px;
        text-transform: uppercase;
        transform: rotateX(40deg);
    }
    .MuiSnackbarContent-root {
        background: $message;
        color: $text;
        font-family: $font;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.29;
        letter-spacing: normal;
        padding: 11px 12px;
        border-radius: 0;
        box-shadow: none;
        margin-bottom: 23px;
    }
    .MuiSnackbarContent-message {
        padding: 0;
    }

    .MuiButtonBase-root.MuiButton-root.MuiButton-sizeSmall, .btn.MuiButton-sizeSmall{
        min-width: auto;
        color: $text
    }
    .MuiButtonBase-root.MuiButton-root:hover, .btn:hover {
        box-shadow: unset;
        background: none;
        outline: none;
        color: $text;
    }
}