.structural-tree-wrap {
    .breadcrumb-text.without-dot {
        &> div {
            width: 100%;
            direction: unset;
        }
    }
    .hr-panel,
    .department-panel {
        .title-block-wrap {
            .dropdown-arrow {
                transform: translate(0, -50%);
            }
        }

        .state-chart {
            width: 100%;
        }

        .dropdown-arrow {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 1;
            transition: ease 0.35s;
            min-width: 24px;
        }

        .right-section {
            .dropdown-arrow {
                top: auto;
            }

            .dropdown-arrow {
                top: auto;
                transform: translate(0, 0%);
            }
        }
        &:not(.Mui-expanded) {
            .dropdown-arrow {

                transform: translate(0, 0%) rotate(-90deg) !important;
            }
            .title-block-wrap {
                .dropdown-arrow {

                    transform: translate(0, -50%) rotate(-90deg) !important;
                }
            }
        }


        .right-section {
            .sensor-name {
                display: block;
            }
        }
    }

    .wrap-hr.monitoring-tree {
        .title-block-wrap {
            display: flex;
            width: 100%;
            height: 25px;
        }
    }

    .hr-panel {
        .MuiAccordionSummary-root.Mui-disabled {
            opacity: 1;
            min-height: 25px !important;

        }

        &.MuiAccordion-root.Mui-disabled {
            background-color: inherit;
        }
    }
}