@import '../../assets/css/variables.scss';

.action-menu {
	position: absolute;
	top: 50%;
	right: 9px;
	transform: translateY(-50%);
	opacity: 1;
	transition: 0.2s linear;
	z-index: 2;

	@media (min-width: $sm) {
		opacity: 0;

		&.visible {
			opacity: 1;
		}
	}

	.MuiIconButton-root {
		padding: 2px;
	}
}