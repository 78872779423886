.setting-page-beacon-table {
    .list .MuiTableCell-root {
        line-height: 16px;

        .activate,
        .activate +
        .action-menu {
            display: none;
        }
    }
}
