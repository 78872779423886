.gateway-edit-modal {
    max-width: 334px;

    textarea::placeholder {
        padding-left: 1px;
    }

    .gateway-file-field {
        .gateway-file-wrapper {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;

            .gateway-file-list {
                display: flex;
                flex-direction: row-reverse;
                flex-wrap: wrap;
                flex: 1;
            }
        }

        .gateway-file-input {
            display: none;
        }
    }

    .label-inline {
        flex-wrap: wrap;
    
        .MuiFormControl-root {
            width: 60%;
        }
    }

    .MuiSvgIcon-root {
        cursor: pointer;
    }
}

.preview-gateway-dialog {
    .MuiDialog-paperScrollBody {
        text-align: center;
    }
}
