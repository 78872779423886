@import '../../assets/css/variables.scss';

.MuiInputBase-root {
	height: 40px;
	box-sizing: border-box;
	cursor: pointer;

	&.MuiOutlinedInput-root {
		font-size: 14px;

		fieldset {
			border-color: #adb5bd;
			background: #fff;
			border-radius: 0;
			padding: 10px 12px;
			top: -6px;
		}

		&:hover {
			.MuiOutlinedInput-notchedOutline {
				border-color: #adb5bd;
			}
		}

		&.Mui-focused {
			.MuiOutlinedInput-notchedOutline {
				border-color: #4092f5;
				border-width: 1px;
			}
		}
	}

	&.MuiOutlinedInput-multiline {
		height: auto;
	}

	.MuiIconButton-root {
		padding: 5px;
	}
}

input.MuiInputBase-input {
	z-index: 1;
	margin: 0 1px;
	font-family: $font;
}

.MuiOutlinedInput-adornedEnd {
	padding-right: 9px;
}

.with-icon {
	.MuiOutlinedInput-root.Mui-error {
		&:before {
			font-family: 'icomoon';
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}

.MuiInputAdornment-root {
	z-index: 2;

	img {
		min-width: 24px;
	}
}

.kheMwv {
	.MuiOutlinedInput-root {
		&:hover {
			&.MuiOutlinedInput-notchedOutline {
				border-color: #4092f5;
			}
		}
	}
}


.form-field,
.form-fields {
	margin: 0 0 23px;
	position: relative;

	.MuiFormLabel-root {
		color: #333;
		margin: 0 0 10px;

		&:empty {
			display: none;
		}
	}
	legend {
		opacity: 0;
		width: 0;
	}
}

.label-inline {
	display: flex;
	align-items: center;

	.MuiFormLabel-root {
		width: 116px;
		margin: 0;
		padding: 0 10px 0 0;
	}

	.MuiFormControl-root {
		flex-grow: 1;
		width: auto;
	}
}

.non-overriden {
	.MuiOutlinedInput-root {
		font-style: italic;
		pointer-events: none;
	}
}

.error-field,
.success-field {
	.MuiInputBase-root {
		padding-right: 25px;

		&:after {
			content: '\e964';
			color: #ff3b30;
			font-family: 'icomoon';
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			position: absolute;
			right: 0px;
			top: 0;
			bottom: 0px;
		}
	}

	.MuiSelect-iconOutlined {
		right: 35px;
	}

	.MuiSelect-outlined.MuiSelect-outlined {
		padding-right: 55px;
	}

	.MuiInputAdornment-positionEnd {
		margin: 0 5px 0 0;
	}
}

div.error-field {
	.MuiOutlinedInput-root {
		fieldset {
			border-color: #ff3b30;
		}
	}
}

div.success-field {
	.MuiOutlinedInput-root {
		fieldset {
			border-color: #72c26a;
		}
	}

	.MuiInputBase-root {
		&:after {
			content: '\e973';
			color: #72c26a;
			font-size: 18px;
		}
	}
}