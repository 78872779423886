@import '../../assets/css/variables';

.edit-dots {
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .config-dots {
        display: flex;
        max-height: 40px;
        padding: 10px 0;
    }

    &.MuiButtonBase-root.MuiButton-root {
        padding: 0;
        min-width: auto;
        box-shadow: none;
        border: none;

        .config-dots {
            fill: $text;
            margin: 0;
            max-width: 10px;
        }

        &:hover {
            border: none;
            box-shadow: none;
        }
    }

    &__menu {

    }

}