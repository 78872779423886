@import "../../assets/css/variables";

.dragged-list {
    margin-top: 24px;
    max-width: 1054px;

    .list-draggable {
        min-height: 40px;
    }

    .item-description-wrap {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        align-items: center;
        display: flex;

        & > span {
            align-items: center;
            width: 33%;
            display: block;
            padding: 0 5px 0 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            word-break: keep-all;

            p {
                margin: 0;
                display: flex;
                align-items: center;
            }
        }
    }

    .wrap-menu-edit {
        display: none;

        .edit-dots.MuiButtonBase-root.MuiButton-root {
            height: 100%;
            background: transparent;
            width: auto;
            display: block;
            position: absolute;
            right: 8px;
            top: 0;

        }

        svg.config-dots {
            cursor: pointer;
            fill: $blue !important;
        }
    }

    .placeholder-props {
        width: 100%;
        border-top: 2px solid #699be4;
        z-index: 2;
        background: #FFFFFF;

        &:before {
            z-index: 2;
            content: '';
            position: absolute;
            left: 0;
            display: block;
            width: 6px;
            height: 6px;
            background: #FFFFFF;
            border: solid 2px #699be4;
            border-radius: 100%;
            top: -4px;
        }
    }

    .wrap-add-category {
        .add-category {
            height: 24px;
            width: 100%;
        }

        input {
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
        }

        .list-draggable__item.MuiListItem-root:hover {
            background-color: inherit;

            input {
                box-shadow: inset 0 0 0 2px rgba(64, 146, 245, 0.01);
            }
        }
    }

    .edit-category,
    .edit-unsorted,
    .add-item,
    .add-category,
    .edit-product {
        &.error-field {
            input {
                //border: 1px solid red;
                box-shadow: inset 0 0 0 1px red !important;
            }
        }
    }

    .right-side-context {
        margin-right: 10px;
        cursor: pointer;
        z-index: 1 !important;
    }
}

.list-draggable {
    flex-direction: column;

    //TODO after add open status updated

    //&__item.MuiListItem-root {
    //    cursor: pointer;
    //
    //    .dropdown-arrow {
    //        transform: rotate(-90deg);
    //        transition: ease 0.35s;
    //    }
    //
    //    &.open {
    //        .dropdown-arrow {
    //            transform: rotate(0);
    //        }
    //    }
    //}

    .MuiButtonBase-root.MuiAccordionSummary-root {
        .dropdown-arrow {
            transform: rotate(-90deg);
            transition: ease 0.35s;
        }

        &.Mui-expanded {
            .dropdown-arrow {
                transform: rotate(0);
            }
        }

    }

    &__item.MuiListItem-root,
    &__item-include.MuiListItem-root {
        border: 1px solid #e6eaed;

        .product-view,
        .dropdown-arrow {
            margin-right: 8px;
            min-width: 24px;
        }
    }

    &__item-include.MuiListItem-root {
        padding-left: 40px;

        &.unsorted {
            padding-left: 8px;
        }
    }

    &__item-wrap.inactive,
    &__item-wrap.inactive:hover {

        .list-draggable__item.MuiListItem-root {
            box-shadow: unset !important;
            background-color: #fff;
        }
    }

    &__item-wrap.active,
    &__item-wrap:hover {
        .list-draggable__item.MuiListItem-root {
            background-color: rgba(64, 146, 245, 0.08);
            box-shadow: inset 0 0 0 2px $blue;
        }

        .wrap-menu-edit {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 10px;
        }
    }

    &__item.MuiListItem-root {
        padding: 7px 8px;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;

        &:hover {
            background-color: rgba(64, 146, 245, 0.08);
            box-shadow: inset 0 0 0 2px red;
            //color: #FFFFFF;
        }
    }

    .MuiInput-root.add-item {
        height: auto;
        line-height: 14px;
        font-size: 14px;
        width: 100%;

        input {
            padding: 0;
        }
    }

    .empty-space {
        width: 50px;
    }

    .color-circle {
        display: inline-block;
        height: 16px;
        width: 16px;
        min-width: 16px;
        margin-right: 14px;
        border-radius: 100%;
    }
}

.expansion-panel {
    .MuiCollapse-hidden {
        height: 0;
        max-height: 0;
        transition: height ease-in 0.5s;
        position: relative;
        top: -40px;

        .MuiCollapse-wrapper {
            max-height: 40px;

            #panel1a-content {
                max-height: 40px;

                .wrapper-draged-element,
                .MuiAccordionDetails-root {
                    max-height: 40px;

                    .list-draggable__item-wrap {
                        position: absolute !important;

                        & > li {
                            min-height: 0.1px;
                            max-height: 0.1px;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
    &.hover {
        display: block;
        .MuiCollapse-wrapper{
            max-width: 40px;
        }

        .MuiCollapse-root.MuiCollapse-hidden {
            visibility: visible;
            min-height: auto !important;
            height: auto !important;
            transition: height ease-in 0.5s;
            max-height: 40px;
        }

        .MuiCollapse-hidden {
            height: inherit;
            max-height: inherit;
            transition: height ease-in 0.5s;
            max-height: 40px;
        }
        #panel1a-content {
            display: block !important;
        }
    }
}