.gateway-edit-form {

    textarea {
        height: auto;
        min-height: 80px;
        border-color: rgb(173, 181, 189);
    }

    .textarea::placeholder {
        padding-left: 1px;
    }

    .version-info {
        display: flex;
        flex-direction: column;
        color: #4A4A4A;

        &-color {
            font-weight: 600;
        }
    }
    .select-box .placeholder {
        color: inherit;
    }
}