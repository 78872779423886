@import "../../assets/css/variables";
.editor-kpi {
    margin-bottom: 23px;
    width: 100%;
    .tox .tox-tbtn__select-label {
        padding: 0 8px;
        background: #FFFFFF;
        margin: 0 !important;
        height: 25px;
        line-height: 25px;
        min-width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        border-radius: 4px;
        color: #74797d;
        min-height: 25px;
        border: solid 1px rgba(116, 121, 125, 0.1);
        cursor: pointer;
    }

    .tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
        background: #f1f1f1 !important;
    }
    .tox .tox-tbtn.tox-tbtn--disabled:focus,
    .tox .tox-tbtn.tox-tbtn--disabled:active {
        background: transparent !important;
    }
    .tox .tox-tbtn--enabled,
    .tox .tox-tbtn:hover {
        background: none !important;
        border: 0;
        box-shadow: none;
        color: #222f3e;

        .tox-tbtn__select-label {
            background: #4092f5;
            color: #FFFFFF;
        }
    }

    .tox .tox-statusbar {
        display: none !important;
    }
    .tox-tinymce {
        border: solid 1px #adb5bd;
    }
    .tox .tox-tbtn--select{
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6){
            span{
                width: 25px;
                line-height: 25px;
                font-size: 18px;
            }
        }
        span {
            border: solid 1px rgba(116, 121, 125, 0.1);
            //color: $text-grey;
            font-family: $font;
        }
    }
    .tox .tox-tbtn--select {
        padding: 0 8px;
    }
}
