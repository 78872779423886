@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-map-pin:before {
	content: "\e974";
	color: #4092f5;
}
.icon-validated-yes:before {
	content: "\e973";
	color: #72c26a;
}
.icon-add .path1:before {
	content: "\e900";
	color: rgb(205, 208, 212);
}
.icon-add .path2:before {
	content: "\e901";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-add .path3:before {
	content: "\e902";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-alert .path1:before {
	content: "\e903";
	color: rgb(205, 208, 212);
}
.icon-alert .path2:before {
	content: "\e904";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-alert .path3:before {
	content: "\e905";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-alert-inverted .path1:before {
	content: "\e906";
	color: rgb(255, 255, 255);
	opacity: 0.4;
}
.icon-alert-inverted .path2:before {
	content: "\e907";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-alert-inverted .path3:before {
	content: "\e911";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-alert-panel:before {
	content: "\e908";
}
.icon-alert-panel-inverted:before {
	content: "\e909";
	color: #fff;
}
.icon-arrow-collapsed:before {
	content: "\e90a";
	color: #adb5bd;
}
.icon-arrow-expanded:before {
	content: "\e90b";
	color: #adb5bd;
}
.icon-calendar .path1:before {
	content: "\e90c";
	color: rgb(205, 208, 212);
}
.icon-calendar .path2:before {
	content: "\e90d";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-category:before {
	content: "\e90e";
	color: #73c16c;
}
.icon-category-checked .path1:before {
	content: "\e90f";
	color: rgb(114, 194, 106);
}
.icon-category-checked .path2:before {
	content: "\e910";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-chevron-down:before {
	content: "\e912";
	color: #fff;
}
.icon-chevron-up:before {
	content: "\e913";
	color: #fff;
}
.icon-comment .path1:before {
	content: "\e914";
	color: rgb(0, 0, 0);
}
.icon-comment .path2:before {
	content: "\e915";
	margin-left: -1em;
	color: rgb(248, 231, 28);
}
.icon-config:before {
	content: "\e916";
	color: #74797d;
}
.icon-config-white:before {
	content: "\e917";
	color: #fff;
}
.icon-corner-drag-handle:before {
	content: "\e918";
}
.icon-different:before {
	content: "\e919";
	color: #a6cfff;
}
.icon-downtime .path1:before {
	content: "\e91a";
	color: rgb(205, 208, 212);
}
.icon-downtime .path2:before {
	content: "\e91b";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-downtime .path3:before {
	content: "\e91c";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-downtime-inverted .path1:before {
	content: "\e91d";
	color: rgb(255, 255, 255);
	opacity: 0.4;
}
.icon-downtime-inverted .path2:before {
	content: "\e91e";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-downtime-inverted .path3:before {
	content: "\e91f";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-downtime-selected:before {
	content: "\e920";
	color: #4092f5;
}
.icon-dropdown-arrow:before {
	content: "\e921";
	color: #545454;
}
.icon-dropdown-arrow-white:before {
	content: "\e922";
	color: #fff;
}
.icon-duration:before {
	content: "\e923";
}
.icon-edit-tree:before {
	content: "\e924";
}
.icon-edit-tree-selected:before {
	content: "\e925";
}
.icon-filter:before {
	content: "\e926";
	color: #545454;
}
.icon-folder .path1:before {
	content: "\e927";
	color: rgb(205, 208, 212);
}
.icon-folder .path2:before {
	content: "\e928";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-folder-inverted .path1:before {
	content: "\e929";
	color: rgb(255, 255, 255);
	opacity: 0.4;
}
.icon-folder-inverted .path2:before {
	content: "\e92a";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-folder-key .path1:before {
	content: "\e92b";
	color: rgb(123, 213, 0);
}
.icon-folder-key .path2:before {
	content: "\e92c";
	margin-left: -1em;
	color: rgb(0, 0, 0);
	opacity: 0.3;
}
.icon-folder-key .path3:before {
	content: "\e92d";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-folder-key-selected .path1:before {
	content: "\e92e";
	color: rgb(123, 213, 0);
}
.icon-folder-key-selected .path2:before {
	content: "\e92f";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-folder-selected .path1:before {
	content: "\e930";
	color: rgb(64, 146, 245);
}
.icon-folder-selected .path2:before {
	content: "\e931";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-function .path1:before {
	content: "\e932";
	color: rgb(205, 208, 212);
}
.icon-function .path2:before {
	content: "\e933";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-function .path3:before {
	content: "\e934";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-function-inverted .path1:before {
	content: "\e935";
	color: rgb(255, 255, 255);
	opacity: 0.4;
}
.icon-function-inverted .path2:before {
	content: "\e936";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-function-selected .path1:before {
	content: "\e937";
	color: rgb(64, 146, 245);
}
.icon-function-selected .path2:before {
	content: "\e938";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-function-selected .path3:before {
	content: "\e939";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-hierarchy-view:before {
	content: "\e93a";
	color: #74797d;
}
.icon-hierarchy-view-active:before {
	content: "\e93b";
	color: #4092f5;
}
.icon-hr .path1:before {
	content: "\e93c";
	color: rgb(205, 208, 212);
}
.icon-hr .path2:before {
	content: "\e93d";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-hr-inverted .path1:before {
	content: "\e93e";
	color: rgb(255, 255, 255);
	opacity: 0.4;
}
.icon-hr-inverted .path2:before {
	content: "\e93f";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-hr-selected .path1:before {
	content: "\e940";
	color: rgb(64, 146, 245);
}
.icon-hr-selected .path2:before {
	content: "\e941";
	margin-left: -1em;
	color: rgb(64, 146, 245);
}
.icon-hr-selected .path3:before {
	content: "\e942";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-key:before {
	content: "\e943";
	color: #7ed321;
}
.icon-key-white:before {
	content: "\e944";
	color: #fff;
}
.icon-low-graphics:before {
	content: "\e945";
	color: #74797d;
}
.icon-low-graphics-active:before {
	content: "\e946";
	color: #4092f5;
}
.icon-minus .path1:before {
	content: "\e947";
	color: rgb(205, 208, 212);
}
.icon-minus .path2:before {
	content: "\e948";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-minus .path3:before {
	content: "\e949";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-normal-view:before {
	content: "\e94a";
	color: #74797d;
}
.icon-normal-view-active:before {
	content: "\e94b";
	color: #4092f5;
}
.icon-off .path1:before {
	content: "\e94c";
	color: rgb(255, 255, 255);
}
.icon-off .path2:before {
	content: "\e94d";
	margin-left: -1em;
	color: rgb(173, 181, 189);
}
.icon-off-circle .path1:before {
	content: "\e94e";
	color: rgb(255, 255, 255);
}
.icon-off-circle .path2:before {
	content: "\e94f";
	margin-left: -1em;
	color: rgb(173, 181, 189);
}
.icon-on:before {
	content: "\e950";
	color: #4092f5;
}
.icon-on-circle .path1:before {
	content: "\e951";
	color: rgb(255, 255, 255);
}
.icon-on-circle .path2:before {
	content: "\e952";
	margin-left: -1em;
	color: rgb(173, 181, 189);
}
.icon-on-circle .path3:before {
	content: "\e953";
	margin-left: -1em;
	color: rgb(64, 146, 245);
}
.icon-parameter-selected .path1:before {
	content: "\e954";
	color: rgb(64, 146, 245);
}
.icon-parameter-selected .path2:before {
	content: "\e955";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-parameter-v-2 .path1:before {
	content: "\e956";
	color: rgb(205, 208, 212);
}
.icon-parameter-v-2 .path2:before {
	content: "\e957";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-parameter-v-2 .path3:before {
	content: "\e958";
	margin-left: -1em;
	color: rgb(116, 121, 125);
	opacity: 0.5;
}
.icon-parameter-v-2 .path4:before {
	content: "\e959";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-parameter-v-2-inverted .path1:before {
	content: "\e95a";
	color: rgb(255, 255, 255);
	opacity: 0.4;
}
.icon-parameter-v-2-inverted .path2:before {
	content: "\e95b";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-parameter-v-2-inverted .path3:before {
	content: "\e95c";
	margin-left: -1em;
	color: rgb(255, 255, 255);
	opacity: 0.5;
}
.icon-parameter-v-2-inverted .path4:before {
	content: "\e95d";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.icon-product .path1:before {
	content: "\e95e";
	color: rgb(205, 208, 212);
}
.icon-product .path2:before {
	content: "\e95f";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-product-view:before {
	content: "\e960";
	color: #74797d;
}
.icon-product-view-selected:before {
	content: "\e961";
	color: #4092f5;
}
.icon-question .path1:before {
	content: "\e962";
	color: rgb(151, 151, 151);
}
.icon-question .path2:before {
	content: "\e963";
	margin-left: -1em;
	color: rgb(142, 142, 142);
}
.icon-remove:before {
	content: "\e964";
	color: #ff3b30;
}
.icon-search:before {
	content: "\e965";
	color: #74797d;
}
.icon-settings .path1:before {
	content: "\e966";
	color: rgb(205, 208, 212);
}
.icon-settings .path2:before {
	content: "\e967";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-settings .path3:before {
	content: "\e968";
	margin-left: -1em;
	color: rgb(116, 121, 125);
}
.icon-start:before {
	content: "\e969";
}
.icon-stop:before {
	content: "\e96a";
}
.icon-subcategory:before {
	content: "\e96b";
	color: #4092f5;
}
.icon-subcategory-checked:before {
	content: "\e96c";
	color: #4092f5;
}
.icon-tall-graphics:before {
	content: "\e96d";
	color: #cdd0d4;
}
.icon-tall-graphics-active:before {
	content: "\e96e";
	color: #4092f5;
}
.icon-unplanned-stop-indicator:before {
	content: "\e96f";
	color: #eb444b;
}
.icon-unread .path1:before {
	content: "\e970";
	color: rgb(0, 0, 0);
}
.icon-unread .path2:before {
	content: "\e971";
	margin-left: -1em;
	color: rgb(255, 81, 72);
}
.icon-unread .path3:before {
	content: "\e972";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
