.panel-menu {
    display: flex;
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translate(0, -50%);
    background: #f7f8f9;
    opacity: 1;
    width: 40px;
    height: 38px;
    margin-top: -2px;

    button:hover {
        background: inherit;
    }

    .MuiIconButton-root {
        padding: 2px;
    }


    .export-item {
        margin: 0 10px 0 0;
    }

    .field-checkbox {
        margin: 0;

        .MuiTypography-body1 {
            font-size: 12px;
            line-height: 1;
        }
    }


    .grapics-item {
        margin: 0 10px 0 0;

        @media(min-width: 1300px) {
            margin: 0 24px 0 0;
        }

        .MuiButtonBase-root {
            min-width: 30px;
        }
    }

    .histogram-item {
        margin: 0 10px 0 0;

        @media(min-width: 1300px) {
            margin: 0 17px 0 0;
        }
    }


}

#panel-menu {
    ul {
        li {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #333333;
            padding: 0 24px;
            line-height: 22px;
            margin: 0;

            .MuiTypography-body1 {
                font-size: 12px;
                max-height: 22px;
            }

            .MuiIconButton-root {
                //padding: 0 9px;
            }

            .MuiSvgIcon-root {
                height: 18px;
                width: 18px;
            }
        }
    }

    .histogram-type {
        padding: 0 5px 0 2px;

        .MuiCheckbox-root {
            padding: 0;
        }

        svg {
            height: 30px;
            width: 30px;
        }
    }


    .export-item {
        margin-left: -8px;

        .MuiIconButton-colorPrimary:hover {
            background: none;
        }
    }

    .export-item-icon {
        padding: 0;
    }

    .histogram-type-item {
        padding: 0;

        & > div {
            width: 100%;
        }

        label {
            width: 100%;
            padding: 0 15px;
            margin: 0;

            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }
        }

        svg {
            padding: 0;
        }

    }

    .MuiIconButton-root:hover,
    .MuiCheckbox-colorPrimary.Mui-checked:hover {
        background: inherit;
    }

    .histogram-type-item:hover {
        background: inherit;
    }


    .desc-item {
        //margin: 0 10px 0 0;

        @media(min-width: 1300px) {
            //margin: 0 20px 0 0;
        }

        .MuiSvgIcon-root,
        .checkbox-icon {
            width: 20px;
            height: 20px;
        }
    }

    .minimap-item {

        .MuiSvgIcon-root,
        .checkbox-icon {
            width: 20px;
            height: 20px;
        }

        .dashboard-mode {
            display: table;
            line-height: 16px;
            border-collapse: separate;
            border-spacing: 5px;
            margin-left: -5px;

            i {
                display: table-cell;
                border-radius: 50%;
                width: 16px;
                min-width: 16px;
                height: 16px;
                vertical-align: middle;
            }

            span {
                margin-top: -10px;
                display: table-cell;
                vertical-align: middle;
            }

            &.online {
                color: #75c36e;

                i {
                    background-color: #75c36e;
                }
            }

            &.offline {
                color: #fa5252;

                i {
                    background-color: #fa5252;
                }
            }
        }

        &:last-child{
            &.MuiListItem-button:hover {
                background-color: inherit;
            }
        }
    }

    .subheader {
        color: #74797D;
        font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
        margin-top: 5px;
    }

    hr {
        margin: 12px auto 0;
        width: 80%;
        border: 1px solid #DADADA;
        opacity: .3;
    }

    .close-button {
        position: absolute;
        top: 8px;
        right: 20px;
        z-index: 2;

        svg {
            height: 30px;
            width: 30px;
        }
    }
}

.panel-menu-mobile {
    .MuiMenu-paper {
        width: 100%;
        height: 100%;
        top: 0 !important;
        left: 0 !important;
        max-width: 100%;
        max-height: 100%;
        zoom: 1.5;
        padding-top: 35px;

        ul {
            li {
                .MuiTypography-body1 {
                    zoom: 1.1;
                }
            }
        }

        .subheader {
            zoom: 1.5;
        }


        .export-item,
        label {
            zoom: 1.5;
        }

        .histogram-type-item label {
            padding: 0 7px !important;
        }

        .export-item {
            margin-left: -15px !important;
        }
    }

}