.svg-preview {
    position: relative;
    margin: 0 auto;
    width: 802px;
    height: 402px;
    border: 1px rgba(0, 0, 0, 0.87) solid;

    .canvas {
        position: relative;
    }

    .font-preloader {
        font-family: "icomoon";
        height: 0;
    }

    .map-editor-left-panel {
        top: 5px;
        left: -70px;
    }

    .map-editor-scale-panel {
        bottom: 5px;
        left: -85px;

        &.ios-style-chrome,
        &.ios-style-safari {
            bottom: 5px;

            @media screen and (max-width: 1300px) and (orientation: landscape){
                bottom: 5px;
            }
        }


        &.ios-style-chrome {
            @media screen and (max-width: 1300px) and (orientation: landscape){
                bottom: 5px;
            }
        }

        &.android-style {
            bottom: 5px;

            @media screen and (max-width: 1300px) and (orientation: landscape){
                bottom: 5px;
            }
        }
    }

    .opacity-slider {
        position: absolute;
        height: 50px;
        right: 0;
        z-index: 3;
    }
}
