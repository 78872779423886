@import "src/core/ui/assets/css/variables";

.wrap-change-password {
    max-width: 360px;
}

.wrapper.change-password-option {
    padding: 28px 32px;

    .title {
        font-family: $font;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: $text;
    }
}