@import "../../../assets/css/variables";

.product-line-wrap {
    display: flex;
    width: 100%;
    flex-direction: row;
    position: relative;

    .product-section {
        position: absolute;
        padding-left: 4px;
        padding-right: 4px;
        z-index: 3;

        //&.last-child{
            &:after{
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 1px;
                height: 1000vh;
                background: #74797d;
                transform:translate3d(0,0,0);
                -webkit-transform:translate3d(0,0,0);
                clear: both;
            }
        //}

        .vertical-line{
            display: block;
            position: absolute;
            left: -1px;
            top: 0;
            width: 1px;
            height: 1000vh;
            background: #74797d;
            transform:translate3d(0,0,0);
            -webkit-transform:translate3d(0,0,0);
        }

        &.next {
            .product-name{
                overflow: visible;
                background: rgba(#e6eaed, 0.7);
                display: inline-flex;
            }
        }
    }

    .product-name {
        position: relative;
        font-family: $font;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: italic;
        line-height: 12px;
        letter-spacing: normal;
        color: $text;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
    }
}