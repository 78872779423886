.content-title.beacon {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 0 !important;

    .message {
        margin-left: 15px;

        .MuiSnackbarContent-root {
            margin: 0;
        }

        .MuiSnackbarContent-action {
            display: none;
        }
    }
}