// custom collapcs created for removing bug in IE 11

.custom-collapse {
    height: auto !important;

    &.MuiCollapse-hidden {
        .custom-collapse-item {
            max-height: 0;
        }
    }

}

.structure-tree {
    .MuiCollapse-hidden {
        #panel1a-content {
            display: none;
        }
    }
}

.custom-collapse-item {
    max-height: 110px; //blocks height change
    min-height: 0 !important;
    transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: inline-flex;
    min-width: 100%;
}