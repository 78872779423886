.control-panel {
    padding: 30px 20px 10px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    z-index: 2;
    position: relative;
    box-shadow: 0px -7px 12px rgba(0, 0, 0, 0.05);
    background: #FFFFFF;

    &:after {
        content: '';
        height: 30px;
        width: 100%;
        display: inline-block;
        position: absolute;
        z-index: 1;
        top: 100%;
        right: 0;
        background: #FFFFFF;
    }

    .pause-icon:hover,
    .pause-icon {
        background: #3f92f5;
        svg {
            fill: #FFFFFF;
        }
    }

    &.bottom {
        position: fixed;
        bottom: 0;
        //width: calc(100% - 320px);
    }

    &.mobile {
        width: 100%;
        position: absolute;
        z-index: 2;
        @media (orientation: landscape) {
            &.ios {
                bottom: 0;
            }
            &.android {
                bottom: 0;
            }
        }
        @media (orientation: portrait) {
            &.ios {
                bottom: 0;
            }
            &.android {
                bottom: 0;
            }
        }
    }
    &.tablet {
        width: 100%;
        position: absolute;
        z-index: 2;
        @media (orientation: landscape) {
            &.ios {
                bottom: 0;
            }
            &.android {
                bottom: 0;
            }
        }
        @media (orientation: portrait) {
            &.ios {
                bottom: 0;
            }
            &.android {
                bottom: 0;
            }
        }

        &.bottom {
            @media (orientation: landscape) {
                &.ios {
                    bottom: 0px;
                }
                &.android {
                    bottom: 0px;
                }
            }
            @media (orientation: portrait) {
                &.ios {
                    bottom: 0px;
                }
                &.android {
                    bottom: 0px;
                }
            }

            &.full-screen {
                @media (orientation: portrait) {
                    &.ios {
                        bottom: 0;
                    }
                    &.android {
                        bottom: 0;
                    }
                }

                @media (orientation: landscape) {
                    &.ios {
                        bottom: 0;
                    }
                    &.android {
                        bottom: 0;
                    }
                }
            }
        }
    }

    .mobile-navigation {
        position: absolute;
        top: -20px;
        height: 30px;
        background: #FFFFFF;
        box-shadow: 0px -7px 12px rgba(0, 0, 0, 0.05);
        width: 100%;
        margin: 0 -20px;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        text-align: center;
        cursor: pointer;
        outline: 0;

        &:hover,
        &:focus {
            outline: none
        }

        svg {
            transform: rotate(180deg);
        }

        &.close {
            svg {
                transform: rotate(0deg);
            }
        }
    }

    &.mobile {
        &.close {
            @media (orientation: landscape) {
                &.ios {
                    bottom: -153px;
                }
                &.android {
                    bottom: -153px;
                }
            }
            @media (orientation: portrait) {
                &.ios {
                    bottom: -248px;
                }
                &.android {
                    bottom: -243px;
                }
            }
        }
    }

    &.tablet {
        &.close {
            @media (orientation: landscape) {
                &.ios {
                    bottom: -163px;
                }

                &.android {
                    bottom: -158px;
                }
            }
            @media (orientation: portrait) {
                &.ios {
                    bottom: -158px;
                }

                &.android {
                    bottom: -158px;
                }
            }
            &.bottom {
                @media (orientation: landscape) {
                    &.ios {
                        bottom: -165px;
                    }
                    &.android {
                        bottom: -165px;
                    }
                }
                @media (orientation: portrait) {
                    &.ios {
                        bottom: -165px;
                    }
                    &.android {
                        bottom: -165px;
                    }
                }

                &.full-screen {
                    @media (orientation: landscape) {
                        &.ios {
                            bottom: -165px;
                        }

                        &.android {
                            bottom: -158px;
                        }
                    }
                    @media (orientation: portrait) {
                        &.ios {
                            bottom: -160px;
                        }

                        &.android {
                            bottom: -158px;
                        }
                    }
                }
            }
        }
    }
}