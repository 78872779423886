@import "../../../core/ui/assets/css/variables";

.left.column-wrap.MuiGrid-item {
    padding-top: 24px;
    padding-right: 20px;
}

.right.column-wrap.MuiGrid-item {
    padding-left: 20px;
    padding-top: 34px;
}

#action-menu {
    ul {
        li {
            font-family: $font;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: $text;
            padding: 0 24px;
            line-height: 32px;
        }
    }
}