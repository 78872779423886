.button-panel {
    position: absolute;
    top: 30px;
    right: 20px;
    z-index: 3;

    .MuiButton-root {
        margin: 0 10px;
        color: rgba(0, 0, 0, 0.54);
        min-width: auto;
        padding: 4px;
        width: 32px;
    }

    .MuiIconButton-root {
        padding: 4px;
    }

    .parameter-btn,
    .close {
        width: 2rem;
        height: 2rem;
    }

    .parameter-btn.active {
        background: #4092F5;
        border-color: #4092F5;

        svg {
            fill: #FFFFFF;
        }
    }
}

.schema-container {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;

    .canvas {
        position: relative;
        overflow: hidden;
        .canvas-container {
            margin: 0 auto;

        }
    }

    .map-editor-left-panel {
        top: auto;
        bottom: 20px;
        left: auto;
        right: 20px;

        .slider-container {
            display: none;
        }

        .slider-btn {
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-color: #E6EAED;
            background: #FFFFFF;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);

            svg {
                height: 16px;
                width: 16px;
            }

            &:first-child {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                border-bottom: none;
            }

            &:last-child {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }
}

.player-title {
    padding: 0 10px;
}

.hmi-drawer-bottom {
    & > div {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
    }
}


.only-mobile {
    @media only screen and (orientation: portrait) {
        .MuiSlider-markLabel {
            font-size: 1.0625rem !important;
        }

        .MuiSlider-marked {
            margin-bottom: 40px !important;
        }
    }

    .hmi-btn-group {

        button {
            user-select: none !important;
            outline: none !important;

            svg,
            span.MuiFab-label {
                user-select: none !important;
                outline: none !important;
            }
        }

        @media only screen and (orientation: portrait) {
            button {
                height: 8rem;
                width: 8rem;
                font-size: 2rem;

                &:not(:first-child) {
                    height: 6rem;
                    width: 6rem;
                }
            }
            svg {
                transform: scale(2);
            }
        }
    }

    .hmi-menu-speed {
        li {
            font-size: 2rem;
        }
    }

    .player-title {
        @media only screen and (orientation: portrait) {
            font-size: 2.5625rem;
            margin: 1.25rem 0 !important;
            background: #FFFFFF;
        }
    }

    .button-panel {
        @media only screen and (orientation: portrait) {
            top: 20px;

            .parameter-btn,
            .close {
                width: 4rem;
                height: 4rem;
            }
        }
    }

    .realtime-switcher-wrapper {
        overflow: hidden;
        position: relative;

        @media only screen and (orientation: portrait) {
            zoom: 1.5;
        }
    }

}

.mobile {
    .map-editor-left-panel {
        @media (orientation: portrait) {
            bottom: 260px;
        }

        @media (orientation: landscape) {
            bottom: auto;
            top: 35px;
        }
    }
}

.only-mobile {
    .map-editor-left-panel {
        @media (orientation: portrait) {
            zoom: 1.7;
            bottom: 260px
        }
        @media (orientation: landscape) {
            zoom: 1;
        }
        //top: 60vh;
    }
}

.hmi-drawer-bottom,
.hmi-drawer-right {
    .common-error {
        padding: 0 15px;
        font-size: 15px;
    }
}