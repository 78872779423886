.form {
    max-width: unset;
    padding-bottom: 60px;
    width: 870px;
}

.nameRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}

.nameInput {
    width: 100%;
    max-width: 400px;
    margin-right: 30px !important;
}

.formButtonRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px
}

.tableBody {
    display: flex;
    flex-direction: column;
}

.svgContent {
    margin: 30px auto 30px 80px;
}
