@import '../../assets/css/variables';

.date {
    position: relative;

    .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
        border: solid 1px #d0d4d6;
        max-height: 40px;
        &.error {
            border: 1px solid red;
        }
    }
    input {
        padding: 5px;
        font-size: 13px;
    }

    .error-text {
        position: absolute;
        top: 100%;
        font-size: 0.75rem;
        margin-top: 8px;
        min-height: 1em;
        text-align: left;
        font-weight: 400;
        line-height: 1em;
        letter-spacing: 0.03333em;
        color: red;
    }
}