.right-sidebar {
    hr {
        margin: 0 -32px 20px;
        border: none;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    h2.form-header-heavy {
        margin: 0 0 10px;
    }

    input.MuiInputBase-input {
        &::-webkit-input-placeholder {
            font-style: italic;
        }

        &::-moz-placeholder {
            font-style: italic;
        }

        &:-ms-input-placeholder {
            font-style: italic;
        }

        &:-moz-placeholder {
            font-style: italic;
        }
    }
}