.rule {
  z-index: 25;

  .line {
    position: absolute;
    top: 109px;
    width: 1px;
    pointer-events: none;
    display: none;
    z-index: 10;
    //background-color: rgba(#000000, .3);
    height: calc(100% - 87px);
    margin-top: -13px;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    box-sizing: content-box;
    border-left: 1px solid rgba(#000000, .3);
    transform: translate3d(0, 0, 0);

    &.touch {
      //background-color: rgb(64, 146, 245);
      border-left: 1px solid rgb(64, 146, 245);
      width: 1px;
      opacity: 1;
      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        width: 2px;
      }
    }
  }

  .time {
    padding: 0 5px;
    border-radius: 3px;
    transform: translate(-104%, 4px);
    position: absolute;
    font-weight: bold;
    font-size: 12px;
    background: rgba(#f6f7f8, 0.7);
    width: auto;
    white-space: nowrap;
  }

  &.mini-map-hidden {
    .line {
      height: calc(100% - 32px);
      top: 54px;
    }
  }
}

.rule-text {
  position: absolute;
  display: block;
  z-index: 999;
  pointer-events: none;
  min-width: 200px;
  text-align: right;
  padding-right: 5px;
  //-webkit-transform: translate3d(0, 0, 0);

  transform: translateX(0) translateY(0) translateZ(0);
  .description {
    margin-left: 6px;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: right;
    color: #545454;
  }
}