@import './src/core/ui/assets/css/variables.scss';
.MuiDrawer-paperAnchorLeft,
.sidebar-left{
  z-index: 0;
  .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: rgba(#4092f5, 0.04);
    position: relative;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-family: $font;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    .MuiTypography-root.MuiTypography-body1 {
      color: #4092f5;
    }
    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background-color: #4092f5;
    }
  }
  .MuiListItem-button:hover {
    background-color: rgba(#4092f5, 0.04);
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $text;
    font-family: $font;
  }
}
