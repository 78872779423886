@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  src: url(./fonts/fa-solid-900.eot);
  src: url(./fonts/fa-solid-900.eot?#iefix) format('embedded-opentype'),
    url(./fonts/fa-solid-900.woff2) format('woff2'),
    url(./fonts/fa-solid-900.woff) format('woff'),
    url(./fonts/fa-solid-900.ttf) format('truetype'),
    url(./fonts/fa-solid-900.svg#fontawesome) format('svg');
}

@import './src/core/ui/assets/css/variables';

.map-editor {
  position: absolute;
  width: 100%;
  height: 100%;

  .font-preloader {
    font-family: 'Font Awesome 5 Free';
    height: 0px;
  }

  .map-editor-canvas-container {
    display: flex;
    flex-direction: column;

    .map-editor-canvas {
      position: relative;
      flex: 1;
    }

    .edit-scheme {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      background-color: #e6eaed;
      text-align: right;

      /* Primary Blue */

      color: #4092F5;
      padding: 20px 24px 5px 5px;
      min-width: 200px;
    }
  }

  .map-editor-title {
    position: absolute;
    top: 20px;
    left: 80px;
    font-size: 24px;
    font-weight: bold;
    z-index: 1;
  }

  .map-editor-object-edit-group {
    position: absolute;
    top: 30px;
    right: 25px;
    font-size: 16px;
    z-index: 1;
    display: flex;

    .objet-title {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        font-weight: bold;
      }

    }

    button.btn {
      margin-left: 24px;
    }
  }

  .map-editor-close {
    position: absolute;
    bottom: 7vh;
    right: 1vw;
    height: 7vh;
    user-select: none;
    touch-action: none;
    -webkit-user-drag: none;

    & > button, & > button:hover {
      color: $text;
    }

    &.ios-style-chrome,
    &.ios-style-safari {
      bottom: 10vh;

      @media screen and (max-width: 1300px) and (orientation: landscape){
        bottom: 23.5vh;
      }
    }

    &.android-style {
      bottom: 160px;
  
      @media screen and (max-width: 1300px) and (orientation: landscape){
        bottom: 100px;
      }
    }
  }
}
