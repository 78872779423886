@import "../../../ui/assets/css/variables";

.add-action {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font: $font;
  color: #333333;

  svg {
    margin-right: 8px;
  }

  &.disabled * {
    opacity: 0.5;
  }
}
