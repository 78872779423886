@import "../../core/ui/assets/css/variables";

.form-kpi {

    .tox .tox-tbtn:focus,
    .tox .tox-tbtn:hover {
        background: inherit;
    }

    textarea{
        background: #FFFFFF;
    }
    textarea:disabled{
        background: inherit;
        opacity: 0.5;
    }

    .MuiInputBase-input {
        -webkit-user-select: none;
        user-select: none;
    }

    .MuiListItem-root.Mui-disabled {
        pointer-events: none;
        padding: 0;
    }

    .view-as-graph {
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: $blue;
        }

        .MuiTypography-body1 {
            font-family: $font;
            font-size: 14px;
        }
    }

    .wrap {
        display: flex;
        flex-direction: row;
    }

    label {
        font-family: $font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: $text;
        margin-bottom: 10px;
    }

    .field {
        &-comment {
            padding: 11px 12px;
            width: 100%;
            border: solid 1px $text-grey;
            //outline: none;
        }
    }

    .available-variables {
        min-width: 280px;
        border-radius: 4px;
        border: solid 1px $text-grey;
        display: flex;
        flex-direction: column;
        &.Mui-disabled {
            opacity: 0.5;
        }

        &-item {
            user-select: none;
            -webkit-user-drag: element;
            color: $text
        }

        .title-listing {
            font-family: $font;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            color: $text;
            padding: 8px 16px;
            width: 100%;
            border-bottom: solid 1px $text-grey;
        }

        li {
            font-family: $font;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.72;
            letter-spacing: normal;
            color: $text;
        }
    }

    .btn-group {
        display: flex;
        justify-content: flex-end;
        //margin-top: 17px;

        button {
            margin-left: 20px;
        }
    }

    .message .MuiSnackbarContent-root {
        margin-bottom: 40px;
    }

    .field-form-wrap {
        &:last-child {
            padding-left: 32px;
        }
    }

    .field-form {
        min-width: 480px;
    }

    /**
    Goal Range style
     */
    .goal-range-group {
        display: flex;
        justify-content: space-between;

        .MuiFormControl-fullWidth {
            min-width: 213px;
        }
    }
}

.field-range-min.MuiListItem-root.Mui-disabled ,
.field-range-max.MuiListItem-root.Mui-disabled {
    padding: 0;
    width: inherit;
}

.form-field.field-name.MuiListItem-root.Mui-disabled{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.wrap-kpi {
    .title {
        font-family: $font;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: $text;
        display: flex;
        position: relative;
        align-items: center;

        &-description {
            font-weight: normal;
            margin-left: 32px;
            .unit-select {
                font-size: 24px;
                font-family: $font;
                border: 1px solid rgba(0, 0, 0, 0.26);
                padding: 0 10px;
                .MuiSelect-select:focus {
                    background: transparent;
                }
            }
        }

        .question {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
        }
    }
}