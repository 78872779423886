@import "./src/core/ui/assets/css/variables";

.row-wide {
  min-width: 665px;

  .MuiGrid-spacing-xs-2 {
    margin: -4px -8px;

    > .MuiGrid-item {
      padding: 4px 8px;
      font-family: $font;
      color: $text;
    }

    > .field-content {
      width: calc(100% - 40px);
      font-family: $font;
      color: $text;
    }
  }

  .field-remove {
    .MuiIconButton-root {
      padding: 0px;
      margin: 8px 0;

    }
  }

  .field-sensor {
    width: 232px;
  }

  .field-oparands,
  .field-value {
    width: 96px;
  }

  .colon {
    line-height: 40px;
  }

  .field-time {
    width: 112px;

    &.hours,
    &.minutes,
    &.seconds {

      width: 62px;
    }

    &.days,
    &.hours,
    &.minutes,
    &.seconds {
      input.MuiInputBase-input {
        padding: 6px 0 6px 8px;
        min-width: 20px;
      }

      .MuiInputAdornment-positionEnd {
        margin-right: 0;
        margin-left: 0;

        p {
          font-size: 14px;
        }
      }

      .error-field .MuiInputBase-root:after,
      .success-field .MuiInputBase-root:after {
        display: none;
        left: 75%;
      }
    }

    &.days {
      input.MuiInputBase-input {
        padding: 6px 4px 6px 8px;
        min-width: 20px;
      }

      .error-field .MuiInputBase-root, .success-field .MuiInputBase-root {
        padding-right: 8px;
      }

      .error-field .MuiInputBase-root:after,
      .success-field .MuiInputBase-root:after {
        display: none;
        left: 85%;
      }
    }
  }

  .field-value {
    width: 135px;
  }

  .filed-status, .field-status {
    flex-grow: 1;

    .form-field {
      &.success-field {
        .list-multiple--header.MuiAccordionSummary-root.Mui-expanded, .list-multiple--header.MuiAccordionSummary-root {
          border-color: #72c26a;
        }
        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
          right: 32px;
        }

      }

      &.Mui-disabled {
        &:after {
          content: none;
        }
      }

      &.error-field {
        .list-multiple--header.MuiAccordionSummary-root.Mui-expanded, .list-multiple--header.MuiAccordionSummary-root {
          border-color: #ff3b30;
        }
        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
          right: 32px;
        }
      }
    }
  }

  .field-comment {
    textarea {
      min-height: 71px;
      font-family: $font;
      font-size: 14px;
      color: $text;
    }
  }

  .field-checkbox {
    margin: 0;

    .MuiTypography-body1 {
      font-size: 14px;
    }
  }

  .field-email {
    width: 123px;
  }

  .btn-add-holder {
    padding-top: 20px;
    margin-bottom: 40px;
  }

  .button-row.flex-end {
    button {
      margin: 0 0 16px;
    }
  }
}

.select-wrap {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  min-width: 660px;

  & > div {
    min-width: 250px;
    margin-right: 16px;
  }

  label {
    font-family: $font;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $text;
    margin-bottom: 10px;
    margin-top: 20px;
  }
}

.alert-form {
  max-width: 1150px;

  .field-value-with-um {
    display: flex;
    width: 138px;
  }

  .inscription-um {
    z-index: 1;
    height: 39px;
    line-height: 39px;
    align-items: center;
    justify-content: center;
    //display: flex;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &-wrapper {
      height: 39px;
      line-height: 39px;
      align-items: center;
      justify-content: center;
      display: inherit;
      max-width: 45px;
      padding: 0 0 0 5px;
    }
  }

  .tip,
  .row-subheader,
  .form-header-heavy {
    font-family: $font;
    color: $text;
  }

  label {
    font-family: $font;
    color: $text;
    font-size: 14px;
  }

  .select-wrap {
    margin-top: 10px;
  }
}

.validation-massage-time-container {
  position: relative;

  .validation-massage.time-errors {
    position: absolute;
    top: -25px;
  }
}

.not-valid {
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    height: calc(100% + 20px);
    width: calc(100% + 64px);
    background: red;
    opacity: .2;
    top: -20px;
    left: -32px;
    z-index: 1;
  }

  input[type=text] {
    box-shadow: none;
    background-color: #f9cbcc !important;
  }

  .multiple-select-list {
    background-color: unset;
  }
}