.map-editor-scale-panel {
  position: absolute;
  bottom: 50px;
  left: 23px;
  z-index: 3;
  width: 70px;

  &.ios-style-chrome,
  &.ios-style-safari {
    bottom: 200px;

    @media screen and (max-width: 1300px) and (orientation: landscape){
      bottom: 120px;
    }
  }


  &.ios-style-chrome {
    @media screen and (max-width: 1300px) and (orientation: landscape){
      bottom: 80px;
    }
  }

  &.android-style {
    bottom: 220px;

    @media screen and (max-width: 1300px) and (orientation: landscape){
      bottom: 80px;
    }
  }

  .scale-ruler {
    width: 100%;
    height: 10px;
    border-left: 2px solid #6c767a;
    border-right: 2px solid #6c767a;
    border-bottom: 2px solid #6c767a;
    margin-bottom: 2px;
  }

  .scale-value {
    text-align: center;
    white-space: nowrap;
  }
}