ul.button-menu {
    padding-top: 0;
    &>li:first-child {
        padding-left: 0;
        padding-right: 5px;
        padding-bottom: 10px;
        background: #FFFFFF;
    }
    li:not(:first-child) {
        width: calc(100% - 5px);
    }
    li.selected,
    li:not(:first-child):hover {
        background: #4092F5;
        color: #FFFFFF;
    ;
    }
}

div.button-menu-scroll-container {
    div.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
        top: 30px !important;
    }
}