.form-field {

    .MuiSelect-select:focus {
        background: inherit;
    }

    .MuiSelect-iconOutlined,
    .MuiSelect-select.MuiSelect-select {
        z-index: 1 !important;
    }


    .checked-beacon {
        display: none;
    }

    .employee {
        display: none;
    }

    &.beacon {
        border-radius: 5px !important;

        fieldset {
            border-radius: 5px !important;
        }
    }

    &.error-field.beacon {
        fieldset {
            top: -5px;
            border-color: transparent;
        }

        border: 1px solid #ff3b30;

        &:after {
            content: '\e964';
            color: #ff3b30;
            font-family: 'icomoon';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            position: absolute;
            right: 0px;
            top: 0;
            bottom: 0px;
        }
    }

    &.success-field.beacon {
        fieldset {
            top: -5px;
            border-color: transparent;
        }

        border: 1px solid #72c26a;

        &:after {
            content: '\e973';
            color: #72c26a;
            font-size: 18px;
            font-family: 'icomoon';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            position: absolute;
            right: 0px;
            top: 0;
            bottom: 0px;
        }
    }

}

#menu-beacon {
    .MuiListItem-root.Mui-selected {
        background: inherit;
    }

    .MuiListItem-root.Mui-selected:hover {
        background-color: rgba(0, 0, 0, 0.08);
    }

    .beacon-item {
        display: flex;
        align-items: center;
        padding-left: 36px;
        position: relative;
    }

    .selected-beacon {
        position: absolute;
        display: block;
        height: 15px;
        width: 15px;
        background: #7ed321;
        border-radius: 100%;
        margin-right: 5px;
        left: 16px;
    }

    .checked-beacon {
        margin-right: 5px;
        left: 12px;
        position: absolute;
        display: block;
        height: 22px;
        width: 22px;
    }

    .employee {
        margin-left: 5px;
    }
}