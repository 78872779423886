.product-page {
    .MuiInputBase-root.MuiInput-root.edit-category {
        input {
            box-shadow: inset 0 0 0 2px rgba(64, 146, 245, 0.01);
        }
    }
    .MuiInputBase-root.MuiInput-root.edit-unsorted,
    .MuiInputBase-root.MuiInput-root.edit-product {
        height: 30px;
        width: 100%;
        input {
            padding-left: 0 !important;
        }
    }
    .MuiInputBase-root.MuiInput-root.edit-category {
        height: 24px;
        width: 100%;
        overflow: visible;
        margin-right: 10px;
        margin-left: -10px;
    }
    .MuiInputBase-root.MuiInput-root.add-category{
        margin-right: 8px;
        margin-left: -10px;
        input {
            padding-left: 10px;
        }
    }
    .MuiInputBase-root.MuiInput-root.add-item{
        height: 24px;
        width: 100%;
        overflow: visible;
        input {
            height: 30px;
        }
    }

    .MuiInputBase-root.MuiInput-root.edit-unsorted,
    .MuiInputBase-root.MuiInput-root.edit-product,
    .MuiInputBase-root.MuiInput-root.edit-category {
        input {
            font-size: 14px;
            font-weight: inherit;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            padding-left: 10px;
            padding-right: 10px;
        }
        .list-draggable__item.MuiListItem-root:hover {
            background-color: inherit;
            input {
                box-shadow: inset 0 0 0 2px rgba(64, 146, 245, 0.01);
            }
        }
    }
}