@import '../../assets/css/variables.scss';

.select-box {
	&.MuiInputBase-root {
		fieldset {
			border-radius: 6px;
		}
	}

	.MuiSelect-select {
		font-family: $font;
		z-index: 1;
		text-align: left;

		&:focus {
			background: transparent;
		}
	}

	.placeholder {
		color: #adb5bd;
	}
}

#menu-role{
	ul {
		li {
			padding:  0 24px;
			line-height: 32px;
			font-family: $font;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			color: $text;
		}
	}
}

.MuiSelect-icon {
	color: #545454;
	z-index: 1;
}

.select-search-input {

	padding: 0.2rem 0.5rem 0.5rem !important;

	.MuiOutlinedInput-input {
		padding-top: inherit;
		padding-bottom: inherit;
	}
}

.select-small {
	height: 24px;
	min-width: 80px;

	.MuiOutlinedInput-input {
		padding: 5px 25px 5px 14px;
	}

	.MuiSelect-iconOutlined {
		right: 0;
	}
}

.MuiSelect-iconOutlined {
	z-index: 2;
}