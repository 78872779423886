@import "../../../core/ui/assets/css/variables";

.list {
    .MuiTableHead-root {
        background: #e6eaed;
        position: relative;
    }

    .table-container {
        table {
            max-width: calc(100% - 1px);
        }
    }

    .MuiTableCell-root {
        padding: 7px 12px;
        font-family: $font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: normal;
        color: $text;
        overflow: hidden;

        @media(max-width: $lg) {
            line-height: 18px;
            padding: 10px 8px;
        }

        .MuiInputBase-root {
            height: 29px;
        }

        .MuiInputBase-input {
            font-family: $font;
        }

        .MuiOutlinedInput-notchedOutline {
            border: none;
        }

        .MuiOutlinedInput-input {
            padding: 6px 32px 7px 0;
        }

        .error-field {
            border-bottom: 1px solid #ff3b30;

            .MuiInputBase-root:after {
                content: none;
            }
        }
    }

    tbody {
        .MuiTableCell-root {
            position: relative;
        }
    }

    thead {
        .MuiTableCell-root {
            border-bottom: 1px solid rgba(51, 51, 51, 0.15);
            white-space: nowrap;
            overflow: hidden;
            background: #e6eaed;
            z-index: 4;

            .column-action {
                float: right;
                cursor: pointer;
                height: 30px;

                img {
                    display: inline;
                    vertical-align: middle;
                }

                &:after {
                    content: "";
                    clear: both;
                }
            }
        }
    }

    .MuiPaper-elevation1 {
        box-shadow: none;
        border-radius: 0;
    }

    .MuiPaper-root {
        background: transparent;
    }

    .MuiTableBody-root {
        background: #FFFFFF;
        border-top: 1px solid rgba(51, 51, 51, 0.15);

        .MuiTableRow-root.Mui-selected {
            border: solid 2px #4dadf7;
            background-color: rgba(77, 173, 247, 0.08);
            overflow: hidden;
        }
    }

    & > .form-field {
        margin-bottom: 4px;
    }
}

.table-pagination {
    min-height: 40px;
    min-width: 600px;
    background: #fff;
    border-top: 1px solid #e0e0e0;

    .MuiTablePagination-selectIcon {
        top: 7px;
    }

    .MuiTablePagination-toolbar {
        flex-wrap: wrap !important;
        justify-content: flex-end;
        padding-top: 0px;
    }

    .footer-info {
        position: absolute;
        justify-content: flex-start;
        left: 0;
        display: flex;
        top: 0;
    }

    .footer-item {
        font-weight: bold;
        margin-right: 10px;
        padding: 0 5px;
    }

    &.footer-info-field{
        .MuiTablePagination-toolbar{
            padding-top: 10px;
            padding-left: 0;
        }
    }
}