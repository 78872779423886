.map-editor-left-panel {
  position: absolute;
  top: 150px;
  left: 40px;
  z-index: 3;

  .slider-btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 1px solid #666;
    border-radius: 4px;
    background-color: #fff;
    margin-left: 6px;
    text-align: center;
    padding-top: 1px;

    @media screen and (max-width: 1300px) and (orientation: landscape){
      margin-bottom: 12px;
    }

    .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }

  .slider-container {
    height: 200px;
    position: relative;
    @media screen and (max-width: 1300px) and (orientation: landscape){
       display: none;
    }

    .MuiSlider-root {
      &.MuiSlider-vertical {
        width: 6px;

        .MuiSlider-rail {
          width: 6px;
          border-left: 1px solid #666;
          border-right: 1px solid #666;
          background-color: #fff;
          opacity: 1;
        }

        .MuiSlider-thumb {
          width: 14px;
          height: 8px;
          border-radius: 0;
          margin-left: -4px;
          margin-bottom: -4px;
          border: 1px solid #666;
          background-color: #fff;
        }
      }
    }
  }

  .grab-tool {

    margin-left: -6px;
    margin-top: 10px;

    &.active {
      color: #4092f5;
    }

    .MuiIconButton-label {
      margin-left: -4px;
    }
  }

  .fit-screen-tool {
    position: relative;
    left: -8px;
    top: 10px;

    &.active {
      color: #4092f5;
    }
  }

  @media (pointer: coarse) {
    .MuiSlider-root.MuiSlider-vertical {
      padding: 0 13px;
    }
  }
}
