@import "../../../core/ui/assets/css/variables";

.edit-sensor {
    &.form-box {
        text-align: left;

        form {
            max-width: 400px;
            margin: 0 auto;
            padding-bottom: 80px;
            min-width: 400px;
            @media screen and (max-width: 410px) {
                min-width: auto;
            }
        }

        fieldset {
            legend {
                //display: none;
                width: 0 !important;
            }
        }
    }

    .MuiOutlinedInput-root,
    .select-box.MuiInputBase-root fieldset,
    .MuiSelect-outlined {
        border-radius: 1px;
    }

    .select-unit {
        margin-bottom: 16px;
    }

    .btn-group {

        .btn.MuiButton-root {
            min-width: 150px;
        }

        button:first-child {
            margin-right: 20px;
        }

    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        display: none;
    }

    .MuiSelect-select.MuiSelect-select {
        z-index: 2;
    }

    .title {
        font-family: $font;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        color: $text;
    }

    .sensor {
        margin-left: 8px;
        width: 24px;
        height: 22px;
        object-fit: contain
    }

    .table-header {
        display: flex;
        align-items: center;
    }

    .table-subheader {
        margin: 20px 0 16px;

        .current-options {
            background-color: #e9ecef;
            font-family: $font;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;

            letter-spacing: normal;
            color: $text;
            display: inline-flex;
            justify-content: space-between;
            min-height: 40px;
            align-items: center;

            &__id {
                display: flex;
                padding: 0 15px;
                align-items: center;

                .name {
                    text-align: center;
                }

                .value {
                    word-break: break-all;
                    text-align: center;
                }
            }

            &__value {
                display: flex;
                padding: 0 15px;
            }

            .value {
                font-weight: bold;
                padding-left: 9px;
            }
        }
    }

    .MuiTypography-body1 {
        font-family: $font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 40px;
        letter-spacing: normal;
        color: $text;
        margin: 0;
    }

    .table-body {
        label {
            font-family: $font;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 40px;
            letter-spacing: normal;
            color: $text;
            margin: 0;
        }
    }
}