.addAction {
    cursor: pointer;
    position: sticky;
    left: 0;
}

.ieFix {
    position: relative;
    left: 10px;
}

.title {
    font-size: 14px;
    line-height: 2;
}
