@import "./src/core/ui/assets/css/variables";

.alert-item {
    &:hover,
    &.selected:hover {
        .add-comment-btn {
            visibility: visible;
        }
    }

    &:hover,
    &.selected,
    &.selected:hover {
        background-color: rgba(64, 146, 245, 0.04);
    }

    &.selected {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            background-color: $blue;
            z-index: 2;
        }
        &.edit {
            z-index: 2;

            .alert-item--wrap {
                background: #FFFFFF;
                z-index: 23;
                position: relative;
                box-shadow: 0 0 7px 0 $blue;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4px;
                    height: 100%;
                    background-color: $blue;
                    z-index: 2;
                }

                &.last-item {
                    position: absolute;
                    bottom: 0;
                }
            }

        }
    }

    .add-comment-btn {
        font-family: $font;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: right;
        color: #4092f5;
        //text-transform: capitalize;
        visibility: hidden;
        cursor: pointer;
    }

    &--wrap {
        padding: 13px 24px 18px;
        border-top: 1px solid $grey;
        margin-bottom: -1px;
        width: 100%;
        display: flex;
        //&:last-child {
        //    border-bottom: 1px solid $grey;
        //}


        .validation-massage {
            transform: translate(0, -21px);
        }

        .submit-group {
            align-items: flex-end;
            justify-content: flex-end;
            display: flex;
            button {
                margin-left: 8px;
            }
        }
        .MuiButtonBase-root.MuiButton-root.MuiButton-containedSecondary:hover, .btn.MuiButton-containedSecondary:hover {
            border: inherit;
        }
    }

    &--path {
        font-family: $font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: $battleship-grey;
        margin-bottom: 9px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .for {
            font-weight: normal;
            color: $battleship-grey;
        }

        .param {
            font-weight: bold;
            color: $text
        }

        .read-icon {
            width: 8px;
            height: 8px;
            min-width: 8px;
            background-color: #ff6b6b;
            border-radius: 100%;
            justify-content: flex-end;
            margin-right: 2px;
            margin-left: 5px;
        }
    }

    &--add-comment {
        textarea {
            height: auto;
            min-height: 60px;
            width: 100%;
            padding: 12px;
            border-radius: 1px;
            border: solid 1px $text-grey;
            background-color: #ffffff;

            &.error-field{
                border: solid 1px red;
            }
        }
    }

    &--add-comment,
    &--comment {
        font-family: $font;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.5px;
        color: $text;
        margin-bottom: 10px;
        word-break: break-word;
    }

    &--duration {
        font-family: $font;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: $text;
        display: flex;
        justify-content: space-between;

        .duration {
            margin-right: 4px;
        }

        .text {
            display: flex;
            align-items: center;
        }
    }

    &--body {
        width: 100%;
    }

}